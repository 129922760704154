//PS_FP_1.0
//Imports
import React, { useState } from 'react';
import { onRequestLinkMail } from '../apiservices/forgetPassword/api'; // Assuming API.ts exports this function
import { resolve } from 'path';
import { useNavigate } from 'react-router';
import PopUp from './Popup';

const ForgetPassword = () => {
  //PS_FP_1.2
  //Variable declaration
  const [mailId, setMailId] = useState('');
  const [showFields, setShowFields] = useState(true);
  const [errorObj, seterrorObj] = useState({
    validationstatus: false,
    emailAddress: "",
    emailRegex: "",
  });
  const navigate = useNavigate()
  const [showPopup, setShowPopup] = useState(false);
  const [errorType, seterrorType] = useState("Error");
  const [errorMessage, seterrorMessage] = useState("");
  //PS_FP_1.1 - 15
  // On clicking Request link button this function is triggered
  const onRequestLink = async () => {
    let temperrorObj:any={
      validationstatus: false,
      emailAddress: "",
      emailRegex: "",
    };
    seterrorObj(temperrorObj)

    if (!mailId || !mailId.match("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$")) {
      
      if(!mailId)
        temperrorObj.emailAddress = "This field is mandatory";
      if(!mailId.match("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$"))
        temperrorObj.emailRegex = "Enter valid email Address"
      seterrorObj(temperrorObj)
      return;
    }
    

    try {
      //ps
      const response = await onRequestLinkMail({emailId:mailId});
      console.log(response,"resp")
      if (response.status === 200) {
        setShowFields(false);
      } else {
        setShowPopup(true);
        if(response.error){
          temperrorObj.emailAddress=response.error 
          seterrorMessage(temperrorObj.emailAddress)
          seterrorObj({...errorObj,emailAddress:response.error})
        }
      }
    } catch (error) {
      setShowPopup(true);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row align-items-center">
        {/* Left Container */}
        {showFields && <div className="col-md-6 respon-container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <h1 className="font-semibold color-black font-32"><span><a href="/" className="back-style"><img src="image/back-icon.svg" alt="back-icon" className="mb-1 me-3"/></a></span>Forgot Password</h1>
            <p className="descrip-label font-16 mb-4">Enter your email for a reset link to regain access to your account.</p>
            <div className="custom-formfield">
              <label className="w-100 mb-1 font-16 font-medium" htmlFor="email-id">Email ID</label>
              <input type="text" className="form-control font-14" placeholder="Enter your Email ID" id="email-id" value={mailId}
            onChange={(e) => setMailId(e.target.value)}/>
            <div className="pt-1">
                {errorObj.emailAddress ? (
                  <span className="color-red font-12 font-regular">
                    {errorObj.emailAddress}
                  </span>
                ) : (
                  <span className="color-red font-12 font-regular">
                    {errorObj.emailRegex}
                  </span>
                )}
              </div>
            </div>
            <button className="btn btn-primary custom-btn w-100 mt-5" onClick={()=>{onRequestLink()}}>Send Reset Link</button>
          </div>
        </div>
        </div>}
        {!showFields &&
        <div className="col-md-6 respon-container">
        <div className="row justify-content-center">
          <div className="col-md-8 ">
            <h1 className="font-medium font-24 mb-3 title-label">Please check your email that you created your account with</h1>
            <p className="descrip-label font-16 mb-3">Remember to check your spam folder if you cannot find the email in your inbox.</p>
            <p className={errorMessage?"descrip-label font-16 mb-4 custom-formfield":"descrip-label font-16 mb-4 custom-formfield"}>Did you not receive email?<a onClick={()=>onRequestLink()} className="font-medium"> Resend email</a></p>
            <span className="color-red font-12 font-regular">{errorObj.emailAddress}</span>
            <button className="btn btn-primary custom-btn w-100 mt-4" onClick={()=>{navigate('/')}}>Back to Sign In</button>
          </div>
        </div>
      </div>}
      
        {/* Right Container */}
        <div className="col-md-6 p-3">
          <div className="login-right-container">
            <div className="d-flex align-items-center justify-content-center flex-column p-4">
              {/* Image Element */}
              <div className="text-left w-100">
                <img src="image/SuperDesk-logo.svg" alt="superdesk-logo" className="login-logo mb-4" />
              </div>
              <img src="image/Login-image.svg" alt="Login-Image" className="login-image-style my-4" />
              {/* Carousel Element - Starts here */}
              <div id="carouselExampleIndicators" className="carousel slide"  data-bs-ride="carousel">
                <div className="carousel-indicators custom-carousel">
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" />
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={1} aria-label="Slide 2" />
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={2} aria-label="Slide 3" />
                </div>
                <div className="carousel-inner text-center p-5">
                  <div className="carousel-item active">
                    <h1 className="font-24 font-medium color-white">Incident Management Automation</h1>
                    <p className="font-16 color-greyv1 font-light px-5">Automate your entire Incident Management process with SuperDesk right from intake to triage to Service Desk centric resolution.</p>
                  </div>
                  <div className="carousel-item">
                    <h1 className="font-24 font-medium color-white">Incident Management Automation</h1>
                    <p className="font-16 color-greyv1 font-light px-5">Automate your entire Incident Management process with SuperDesk right from intake to triage to Service Desk centric resolution.</p>
                  </div>
                  <div className="carousel-item">
                    <h1 className="font-24 font-medium color-white">Incident Management Automation</h1>
                    <p className="font-16 color-greyv1 font-light px-5">Automate your entire Incident Management process with SuperDesk right from intake to triage to Service Desk centric resolution.</p>
                  </div>
                </div>
              </div>
              {/* Carousel Element - Ends here */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;