//PS_SI_1.0
//Imports
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API } from '../apiservices/signIn/api'; // hypothetical path to API.ts
import CryptoJS from 'crypto-js';
import { encryptPassword } from '../constant';
import PopUp from './Popup';
import { getAssessmentData, } from '../constant';
import { Loader } from './Loader';
// PS_SI_1.2
//interface declaration
interface LoginDetails {
  emailAddress: string;
  password: string;
}


const SignInForm = () => {
  //PS_SI_1.1
  //Variable declaration
  const navigate = useNavigate();
  const [showEye, setShowEye] = useState(false);
  const [signInDetailsObj, setSignInDetailsObj] = useState<LoginDetails>({ emailAddress: '', password: '' });
  const [errorObj, seterrorObj] = useState({
    validationstatus: false,
    emailAddress: "",
    emailRegex: "",
    password: "",
    passwordReg: "",
  });
  
  const [showPopup, setShowPopup] = useState(false);
  const [errorType, seterrorType] = useState("Error");
  const [errorMessage, seterrorMessage] = useState("Something Went Wrong!!!");
  const [showLoader, setshowLoader] = useState(false)
  useEffect(() => {
    sessionStorage.clear();
}, []);
// PS_SI_1.3 - 1.22
//On clicking of sign In button this function is triggered
  const onSignIn = async () => {
    // Check if fields are empty
    let temperrorObj:any={
      validationstatus: false,
      emailAddress: "",
      emailRegex: "",
      password: "",
      passwordReg: "",
    };
    seterrorObj(temperrorObj)
    if (!signInDetailsObj.emailAddress || !signInDetailsObj.password || !signInDetailsObj.emailAddress.match("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$")) {
      
      if(signInDetailsObj.emailAddress=="")
        temperrorObj.emailAddress = "This field is mandatory";
      if(!signInDetailsObj.password)
        temperrorObj.password = "This field is mandatory";
      if(!signInDetailsObj.emailAddress.match("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$") && signInDetailsObj.emailAddress!="")
        temperrorObj.emailRegex = "Enter Valid Email ID"
      seterrorObj(temperrorObj)
      return;
    }
    
    const encryptedPassword = encryptPassword(signInDetailsObj.password);
    const credentials = { ...signInDetailsObj, password: encryptedPassword };
    try {
      setshowLoader(true)
      const signInResponse = await API.logInDetailsObj(credentials);
      if (signInResponse.status === 200) {
        sessionStorage.clear();
        sessionStorage.setItem("role", signInResponse.rows[0].dashboardEntitlement);
        sessionStorage.setItem("user", signInResponse.rows[0].user);
        sessionStorage.setItem("userId", signInResponse.rows[0].userId);
        sessionStorage.setItem("useremail", signInResponse.rows[0].userEmail);
        sessionStorage.setItem("jwt", signInResponse.rows[0].jwt);
        if (signInResponse.rows[0].resetPassword==='true') {
          navigate('/resetPasswordHub');
        } else if (!signInResponse.rows[0].firstTimeLogin) {
          if(signInResponse.rows[0].dashboardEntitlement==="Customer Service")
            navigate('/customerService');
          if(signInResponse.rows[0].dashboardEntitlement==="HRSD")
            navigate('/hrsd');
          if(signInResponse.rows[0].dashboardEntitlement==="ITOPS"||signInResponse.rows[0].dashboardEntitlement==="Admin")
            navigate('/itops');
          if(signInResponse.rows[0].dashboardEntitlement==="Cloud")
            navigate('/cloudCost');
        } 
        else {
          setShowPopup(true);
        }
      } else {
        if((signInResponse.status === 400)){
          seterrorObj(temperrorObj)
          if(signInResponse.error==="Password or Email Address is incorrect"){
            seterrorObj({
              ...temperrorObj,
              password: "Please enter the valid Email ID & Password"
            })}
            else if(signInResponse.error){
                seterrorObj({
                  ...temperrorObj,
                  password: signInResponse.error
                })
            }
        }
      }
      setshowLoader(false)
    } catch (error) {
      setShowPopup(true);
    }
  };



  return (
    <div className="container-fluid">
    <Loader loading={!showLoader} />
      <div className="row align-items-center">
        {/* Left Container */}
        <div className="col-md-6 respon-container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <h1 className="font-semibold color-black font-32 text-center mb-5">Get Started with <span className="brand-gradient-text">SuperDesk</span></h1>
              <div className="custom-formfield">
                <label className="w-100 mb-1 font-16 font-medium" htmlFor="Email ID">Email ID</label>
                <input 
                  type="text" 
                  className="form-control font-14" 
                  placeholder="Enter your Email ID" 
                  id="Email ID" 
                  value={signInDetailsObj.emailAddress}
                  onChange={(e) =>
                    setSignInDetailsObj({
                      ...signInDetailsObj,
                      emailAddress: e.target.value,
                    })
                  } />
              </div>
              <div className="pt-1">
                {errorObj.emailAddress!=="" ? (
                  <span className="color-red font-12 font-regular">
                    {errorObj.emailAddress}
                  </span>
                ) : (
                  <span className="color-red font-12 font-regular">
                    {errorObj.emailRegex}
                  </span>
                )}
              </div>
              <div className="custom-formfield mt-4 ">
                <label className="w-100 mb-1 font-16 font-medium" htmlFor="Password">Password</label>
                <span className="position-relative">
                  <input type={showEye ? 'text' : "password" } className="form-control font-14 pe-5" placeholder="Enter your Password" id="Password" minLength={8}
                        maxLength={12}
                        value={signInDetailsObj.password}
                        onChange={(e) =>
                          setSignInDetailsObj({
                            ...signInDetailsObj,
                            password: e.target.value,
                          })
                        }/>
                    <img src="image/password-eye.svg" alt="Open-Password" onClick={()=>{setShowEye(!showEye)}} className=" position-absolute password-position"/>
                </span>
                <span className="color-red font-12 font-regular">
                  {errorObj.password!==""?errorObj.password:errorObj.passwordReg}
                </span>
                <a href="#" className="font-14 font-medium d-block mt-2 text-end" onClick={() =>navigate("/ForgetPassword")}>Forgot Password?</a>
              </div>
              <button className="btn btn-primary custom-btn w-100 mt-5" onClick={() =>{onSignIn()}}>Sign In
              </button>
            </div>
          </div>
        </div>
        {/* Right Container */}
        <div className="col-md-6 p-3">
          <div className="login-right-container">
            <div className="d-flex align-items-center justify-content-center flex-column p-4">
              {/* Image Element */}
              <div className="text-left w-100">
                <img src="image/SuperDesk-logo.svg" alt="superdesk-logo" className="login-logo mb-4" />
              </div>
              <img src="image/Login-image.svg" alt="Login-Image" className="login-image-style my-4" />
              {/* Carousel Element - Starts here */}
              <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators custom-carousel">
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1" />
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2" />
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3" />
                </div>
                <div className="carousel-inner text-center p-5">
                <div className="carousel-item active">
                    <h1 className="font-24 font-medium color-white">Incident Management Automation</h1>
                    <p className="font-16 color-greyv1 font-light px-5">Automate your entire Incident Management process with SuperDesk right from intake to triage to Service Desk centric resolution.</p>
                  </div>
                  <div className="carousel-item">
                    <h1 className="font-24 font-medium color-white">Incident Management Automation</h1>
                    <p className="font-16 color-greyv1 font-light px-5">Automate your entire Incident Management process with SuperDesk right from intake to triage to Service Desk centric resolution.</p>
                  </div>
                  <div className="carousel-item">
                    <h1 className="font-24 font-medium color-white">Incident Management Automation</h1>
                    <p className="font-16 color-greyv1 font-light px-5">Automate your entire Incident Management process with SuperDesk right from intake to triage to Service Desk centric resolution.</p>
                  </div>
                </div>
              </div>
              {/* Carousel Element - Ends here */}
            </div>
          </div>
        </div>
      </div>
      {showPopup ? (
        <PopUp
          errorType={errorType}
          errorMessage={errorMessage}
          method={setShowPopup}
        />
      ) : null}
    </div>
  );
};

export default SignInForm;