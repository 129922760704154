import CryptoJS from 'crypto-js';
export const userName = (sessionStorage.getItem("user") || "");
export const role = (sessionStorage.getItem("role") || "");
export const mailId = (sessionStorage.getItem("useremail") || "");
export let authToken = (sessionStorage.getItem("jwt") || "");
// PS_SI_1.7 - 8
// This is used for encrypting the password
export const encryptPassword = (password: string): string => {
    const secretkey = getReportData(process.env.REACT_APP_PROFILE);
    return CryptoJS.AES.encrypt(password, secretkey).toString();
  };


  export function getAssessmentData(i: any) {
    let s: any = []
    let p: any = []
    i.split("").forEach((c: any, n: any) => {
      if (i.charCodeAt(n) <= 99) {
        p.push(n)
        s.push(i.charCodeAt(n).toString() + 0)
      }
      else s.push(i.charCodeAt(n).toString())
    })
    const es = (btoa(s.concat('') + p) + btoa(s.length) + (btoa(s.length).length))
    console.log(es.toString(),i);
  }

  export function getReportData(es: any) {
    let pi = []
    let rs = ""
    let al: any = ""
    for (let i = es.length - 1; i >= 0; i--) {
      if (!isNaN(es.split("")[i])) {
        al += es.split("")[i]
      }
      else {
        break
      }
    }
    al = al.split('').sort().join('')
    let pl: any
    pl = es.slice(((es.length - 1) - al), es.length)
    let dp = ""
    for (let i = 0; i < pl.length; i++) {
      if (isNaN(pl.split("")[i])) {
        dp += pl.split("")[i]
      }
      else {
        break;
      }
    }
    dp = atob(dp)
    es = es.split(pl)[0]
    es = atob(es).split(',')
    let ea = es.slice(0, dp)
    pi = es.slice(dp, es.length)
    for (let i = 0; i < ea.length; i++) {
      if (ea[i].length > 3) {
        ea[i] = ea[i].split('').slice(0, 3).join('')
      }
    }
    pi.forEach((e: any) => {
      ea[e] = ea[e]?.slice(0, 2)
    })
    ea.forEach((e: any) => {
      rs += String.fromCharCode(e)
    })
    return rs;
  }