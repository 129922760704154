import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
    const navigate = useNavigate();

    return (
        <div className="container-fluid text-center">
            <img src="image/404-error.svg" alt="error-image" className="top-img" />
            <div className="text-center error-page h-100vh">
                <h4 className="font-24 font-bold font-188 color-legend-purplev2 error-text mt-3">404</h4>
                <p className="font-20 font-regular color-grey-v5">Oops! The page you are looking for cannot be found</p>
                <button className="btn dark-btn px-3 py-2 font-16 font-light" onClick={()=>{navigate('/')}}>Go Back to Sign In</button>
            </div>
            <div className="fixed-bottom mb-2 text-center">
                <img src="image/SD-logo.svg" alt="superdesk-logo" />
            </div>
        </div>
    )
};

export default ErrorPage;