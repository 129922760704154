const PopUp = (props: any) => {
    const { errorType, errorMessage,method } = props;
    const bindPopup = () => {
      return (
        <>
        <div className="body-bg model open">
        <div
          className="modal fade show d-block"
          id="Errorpopup"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="ErrorpopupLabel"
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header justify-content-center px-5 pt-4 border-0">
              {errorType === "Error" || errorType === "Payment Failed"?<img src="image/error-popup-icon.svg" alt="error-popup-icon" />:<img src="Images/success-popup-icon.svg" alt="success-popup-icon"/>}
              </div>
              <div className="modal-body text-center border-0 py-2 px-5">
                <h5 className="popup-header" id="ErrorpopupLabel">
                  {errorType}
                </h5>
                <p className="popup-text mt-3 mb-2">{errorMessage}</p>
              </div>
              <div className="modal-footer border-0 justify-content-center px-5 mb-3">
                {/* PS_PU_1.4 - 6 */}
                <button
                  type="button"
                  className="btn cust-primary-btn"
                  data-bs-dismiss="modal"
                  onClick={() => method(false)}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
          <div className="modal-backdrop fade show"></div>
          </div>
          </>
      );
    };
  
    return bindPopup();
  };
  
  export default PopUp;
  