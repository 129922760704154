import { useEffect, useState } from "react";
import {
  getApiGraphData,
  getApiTotalCost,
  getApiUsecase,
} from "../apiservices/cloudCost/api";
import ExcelJS from "exceljs";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
  PieChart,
  Pie,
  Cell,
  Label,
  Legend,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  LabelList,
  ResponsiveContainer,
  CartesianGrid,
  Line,
  ComposedChart,
  AreaChart,
  Area,
} from "recharts";
import { ConfigProvider, DatePicker, Space } from "antd";
import LeftsidePanel from "./leftsidePanel";
import SessionExpiredPopup from "./sessionExpired";
import { Loader } from "./Loader";
import { useActionData } from "react-router-dom";
const CloudCost = () => {
  // PS_FE_02 - PS_FE_06
  //Declare all the required state varibales and state setter function

  const { RangePicker } = DatePicker;
  const [totalCost, setTotalCost] = useState<any>();
  const [showLoader, setshowLoader] = useState(false);
  const [rerender, setrerender] = useState(false);
  const [graphData, setGraphData] = useState([
    {
      name: "Jan",
      actualCost: 0,
      forecastCost: 0,
    },
  ]);
  const [usecaseData, setUsecaseData] = useState([
    {
      usecase_name: " ",
      usecase_actual: 0,
      usecase_forecast: 0,
      provider: " ",
      location: " ",
      services: [
        {
          usecase_name: " ",
          service_name: " ",
          sku: " ",
          purpose: " ",
          actualCost: " ",
        },
      ],
    },
  ]);
  const [serviceData, setServiceData] = useState();
  const [showFilter, setShowFilter] = useState(true);
  const [filterFromDate, setFilterFromDate] = useState("");
  const [filterToDate, setFilterToDate] = useState("");
  const [sessionExpired, setsessionExpired] = useState(false);
  const [sortDirection, setsortDirection]= useState("")
  const [sortedBy, setSortedBy] = useState("")
  const [costEstimationObject, setCostEstimationObject] = useState({
    filterType: "Current Month",
    fromDate: "",
    toDate: "",
    customerId: sessionStorage.getItem("userId"),

  });



  //PS_FE_07
  // define the useEffect variable with the necessary dependency state variable and functions
  useEffect(() => {
    getGraphData();
    getTableUsecase();
    getTotalCost();
  }, [costEstimationObject]);

  //PS_FE_08 - PS_FE_21
  // to get the total actual cost and forecast cost
  const getTotalCost = async () => {
    setshowLoader(true);
    try {
      const response =
        costEstimationObject.filterType === "Custom Date Range" &&
          costEstimationObject.fromDate === "" &&
          costEstimationObject.toDate === ""
          ? " "
          : await getApiTotalCost(costEstimationObject);

      if (response.status == 200) {
        setTotalCost(response);
      } else if (response.status === 401) {
        setsessionExpired(true);
      }
    } catch (error) {
      console.log(error);
    }
    setshowLoader(false);
  };

  //to get the usecase list for table
  //   PS_FE_43 - PS_FE_73
  const getTableUsecase = async () => {
    setshowLoader(true);
    try {
      if (
        costEstimationObject.filterType === "Custom Date Range" &&
        costEstimationObject.fromDate === "" &&
        costEstimationObject.toDate === ""
      ) {
        return;
      }
      const response = await getApiUsecase(costEstimationObject);

      if (response.status == 200) {
        setUsecaseData(response.rows);
      } else if (response.status === 401) {
        setsessionExpired(true);
      }
    } catch (error) {
      console.log(error);
    }
    setshowLoader(false);
  };


  // to get graph data for graph
  // PS_FE_22 - PS_FE_42
  const getGraphData = async () => {
    setshowLoader(true);
    try {
      if (
        costEstimationObject.filterType === "Custom Date Range" &&
        costEstimationObject.fromDate === "" &&
        costEstimationObject.toDate === ""
      ) {
        return;
      }
      const response = await getApiGraphData(costEstimationObject);

      if (response.status == 200) {
        setGraphData(response.costResult);
      } else if (response.status === 401) {
        setsessionExpired(true);
      }
    } catch (error) {
      console.log(error);
    }
    setshowLoader(false);
  };
  //PS_CS_1.70 - 71
  //For impacts graph tooltip
  const CustomTooltip = ({ active, payload, coordinate }: any) => {
    if (active && payload && payload.length) {
      const withAppValue = payload[0].value;
      const withoutAppValue = payload[1].value;
      let check = !(coordinate.x < 900);
      return (
        <div className="d-flex">

          <div className={`${check ? "custom-tooltip1" : "custom-tooltip"} shadow bg-white p-2 mt-4`}>
            <div className="d-flex justify-content-between gap-2 mb-2">
              <span className="purple-legend-style font-12 font-regular color-greyv1 me-3">Actual Cost</span>
                          <span className="font-12 font-semibold color-legend-purplev2">{withAppValue === '0.00'?"--":withAppValue}</span>
            </div>
            <div className="d-flex justify-content-between gap-2">
              <span className="green-legend-style font-12 font-regular color-greyv1">Forecast Cost</span>
                          <span className="font-12 font-semibold color-legend-purplev2">{withoutAppValue === '0.00'?"--":withoutAppValue}</span>
            </div>

          </div>
        </div>
      );
    }

    return null;
  };

  // to Handle the PDF download functionality
  //PS_FE_74 - PS_FE_109
  const downloadPdf = async () => {
    // let input: any = pdfContent();
    let input: any = document.getElementById("pdf-content");
    let dashboard: any = document.getElementById("dashboard-content");

    const options = {
      letterRendering: true,
    };
    input.style.display = "block";
    dashboard.style.display = "none";

    (html2canvas as any)(input, options).then((canvas: any) => {
      const imageData = canvas.toDataURL("image/jpeg");
      const pdf = new jsPDF();
      let d = new Date();
      let imgWidth = 210;
      let pageHeight = 294;

      let imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;
      pdf.addImage(imageData, "JPEG", 0, 0, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imageData, "JPEG", 0, position, imgWidth, imgHeight + 15);
        heightLeft -= pageHeight;
      }
      pdf.save("CloudCostEstimation.pdf");
    });
    input.style.display = "none";
    dashboard.style.display = "block";
  };


  // PC_FE_123 - PC_FE_134
  // Handle the sort functionality for the usecase table 

  const UsecaseSort =(columnName:string,sortOrder:string) =>{

    setsortDirection(sortOrder)
    setSortedBy(columnName)
    let sortedData:any;
    switch (columnName){
      case "Usecase":
        if(sortOrder === "asc"){
          sortedData = usecaseData.sort((item1:any,item2:any)=>{
            if(item1.usecase_name < item2.usecase_name) return -1;
            if(item1.usecase_name > item2.usecase_name) return 1;
            return 0;
          })
        }else{
          sortedData = usecaseData.sort((item1:any,item2:any)=>{
            if(item1.usecase_name > item2.usecase_name) return -1;
            if(item1.usecase_name < item2.usecase_name) return 1;
            return 0;
          })
        }
        break;
      case "Actual Cost":
        if(sortOrder === "asc"){
          sortedData = usecaseData.sort((item1:any,item2:any)=>{
            if(parseFloat(item1.usecase_actual) < parseFloat(item2.usecase_actual)) return -1;
            if(parseFloat(item1.usecase_actual) > parseFloat(item2.usecase_actual)) return 1;
            return 0;
          })
        }else{
          sortedData = usecaseData.sort((item1:any,item2:any)=>{
            if(parseFloat(item1.usecase_actual) > parseFloat(item2.usecase_actual)) return -1;
            if(parseFloat(item1.usecase_actual) < parseFloat(item2.usecase_actual)) return 1;
            return 0;
          })
        }
        break;
      case "Forecasting Cost":
        if(sortOrder === "asc"){
          sortedData = usecaseData.sort((item1:any,item2:any)=>{
            if(parseFloat(item1.usecase_forecast) < parseFloat(item2.usecase_forecast)) return -1;
            if(parseFloat(item1.usecase_forecast) > parseFloat(item2.usecase_forecast)) return 1;
            return 0;
          })
        }else{
          sortedData = usecaseData.sort((item1:any,item2:any)=>{
            if(parseFloat(item1.usecase_forecast) > parseFloat(item2.usecase_forecast)) return -1;
            if(parseFloat(item1.usecase_forecast) < parseFloat(item2.usecase_forecast)) return 1;
            return 0;
          })
        }
        break;
    }
    setUsecaseData(sortedData)
    if(rerender)
      setrerender(false)
    else
      setrerender(true)
  }
  //PC_FE_135 - PC_FE_148
  // Handle the sort functionality for the service table 
  const serviceSort =(index:any,columnName:string,sortOrder:string) =>{

    let sortedData:any;
    setsortDirection(sortOrder)
    setSortedBy(columnName)
    switch (columnName){
      case "service_name":
        if(sortOrder === "asc"){
          sortedData = usecaseData[index].services.sort((item1:any,item2:any)=>{
            if(item1.service_name < item2.service_name) return -1;
            if(item1.service_name > item2.service_name) return 1;
            return 0;
          })
        }else{
          sortedData = usecaseData[index].services.sort((item1:any,item2:any)=>{
            if(item1.service_name > item2.service_name) return -1;
            if(item1.service_name < item2.service_name) return 1;
            return 0;
          })
        }
        break;
      case "actualCost":
        if(sortOrder === "asc"){
          sortedData = usecaseData[index].services.sort((item1:any,item2:any)=>{
            if(parseFloat(item1.actualCost) < parseFloat(item2.actualCost)) return -1;
            if(parseFloat(item1.actualCost) > parseFloat(item2.actualCost)) return 1;
            return 0;
          })
        }else{
          sortedData = usecaseData[index].services.sort((item1:any,item2:any)=>{
            if(parseFloat(item1.actualCost) > parseFloat(item2.actualCost)) return -1;
            if(parseFloat(item1.actualCost) < parseFloat(item2.actualCost)) return 1;
            return 0;
          })
        }
        break;
    }
    let temp = usecaseData
    temp[index].services = sortedData
    setUsecaseData(temp)
    if(rerender)
      setrerender(false)
    else
      setrerender(true)
  }

  const calculateYaxis = (graphData: any) => {
    const maxActualCost = graphData.reduce((max: any, item: any) => {
      return Math.max(max, parseInt(item.actualCost));
    }, 0);

    const maxForecastCost = graphData.reduce((max: any, item: any) => {
      return Math.max(max, parseInt(item.forecastCost));
    }, 0);

    return maxActualCost + maxForecastCost;
  };


  // PS_FE_110- PS_FE_122
  // logics to handle download excel 
  const downloadExcel = async () => {

    setshowLoader(true);
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Sheet1");

      let exportToExcel = [
        ["Total Actual Cost", "Total Forecast Cost"],
        [
          totalCost?.actualCost === null
            ? "$0"
            : "$" + totalCost?.actualCost.toString(),
          totalCost?.forecastCost === null
            ? "$0"
            : "$" + totalCost?.forecastCost.toString(),
        ],
        [""],
      ];
      let val = ["Impacts Based on"];
      exportToExcel.push(val);
      val = ["Date/ Month", "Actual Cost", "Forecast Cost"];
      exportToExcel.push(val);
      graphData.map((value: any, index: any) => {
        val = [
          value.name === null || undefined ? "-" : value.name.toString(),
          value.actualCost === null || undefined ? "-" :  "$" + value.actualCost.toString(),
          value.forecastCost  === null || undefined ? "-" : "$" + value.forecastCost.toString() ,
        ];
        exportToExcel.push(val);
      });
      val = [""];
      exportToExcel.push(val);
      val = [
        "Usecase",
        "Provider",
        "Location",
        "Actual Cost",
        "Forecasting Cost",
      ];
      exportToExcel.push(val);
      usecaseData.map((value: any, index: any) => {
        val = [
          value.usecase_name  === null || undefined ? "-" :  value.usecase_name,
          value.provider === null || undefined ? "-" : value.provider,
          value.location === null || undefined ? "-" : value.location ,
          value.usecase_actual === null || undefined ? "-" : "$"+ value.usecase_actual.toString(),
          value.usecase_forecast === null || undefined ? "-" : "$"+ value.usecase_forecast.toString(),
        ];
        exportToExcel.push(val);
        val = ["", "Service's", "Purpose", "SKU's", "Actual Cost"];
        exportToExcel.push(val);
        value.services.map((value1: any, index1: any) => {
          val = [
            "",
            value1.service_name  === null || undefined ? "-" :  value1.service_name.toString(),
            value1.purpose === null || undefined ? "-" : value1.purpose.toString() ,
            value1.sku === null || undefined ? "-" : value1.sku.toString(),
             value1.actualCost === null || undefined ? "-" :  "$" + value1.actualCost.toString(),
          ];
          exportToExcel.push(val);
        });
      });

      // Add data to the worksheet
      exportToExcel.forEach((rowData) => {
        worksheet.addRow(rowData);
      });
      worksheet.eachRow((row: any) => {
        row.eachCell((cell: any) => {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });
      });
      worksheet.eachRow((row: any) => {
        row.eachCell((cell: any) => {
          cell.alignment = { wrapText: true };
        });
      });

      worksheet.columns.forEach((column: any) => {
        column.width = 50; // You can set any desired width here
      });
      const targetRowDataList = [
        ["Total Actual Cost", "Total Forecast Cost"],
        ["Impacts Based on"],
        ["Date/ Month", "Actual Cost", "Forecast Cost"],
        ["Usecase", "Provider", "Location", "Actual Cost", "Forecasting Cost"],
        ["", "Service's", "Purpose", "SKU's", "Actual Cost"],
      ];
      targetRowDataList.forEach((targetRowData) => {
        // Iterate over each row in the worksheet
        worksheet.eachRow((row: any) => {
          // Check if the row data matches the current target row data
          let match = true;
          targetRowData.forEach((targetValue, index) => {
            if (row.getCell(index + 1).value !== targetValue) {
              match = false;
              return;
            }
          });

          if (match) {
            // Bold the cells in the matching row
            row.eachCell((cell: any) => {
              cell.font = { bold: true };
            });
          }
        });
      });
      // Generate a blob from the Excel workbook
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element and click it to initiate the download
      const a = document.createElement("a");
      a.href = url;
      a.download = "CloudCostHub.xlsx";
      a.click();

      // Release the URL object
      window.URL.revokeObjectURL(url);
    } catch (error) {
      window.location.href = '/404';

    }
    setshowLoader(false);
  };

  // Date picker Onchange
  const datePickerOnchange = (date: any, dateString: any) => {
    setFilterFromDate(dateString[0]);
    setFilterToDate(dateString[1]);
  };

  const maxValue = calculateYaxis(graphData);
  const quarter = maxValue / 4;
  let avgValues = [0, Math.round(quarter), Math.round(quarter * 2), Math.round(quarter * 3), Math.round(maxValue)];
  let total = 0
  let costFlag;
  avgValues.forEach(item => {
    item >= 10000 ? costFlag = true : costFlag = false;
    total += item;
  });
  if (total === 0) {
    avgValues = [0, 10, 20, 30]
  }

  return (
    <div className="font-regular body-color">
      <Loader loading={!showLoader} />
      <div id="pdf-content" style={{ margin: '0%', padding: '0%', display: 'none' }}>
        <div style={{ backgroundColor: 'rgb(246, 246, 246)', fontFamily: 'sans-serif', boxSizing: 'border-box', lineHeight: '1.5', padding: '40px' }}>
          <div style={{ width: '50%', display: 'flex' }}>
            <h2 style={{ margin: '1% 1% 2% 0px', fontSize: '32px' }}>Cloud Cost</h2>
          </div>
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <div style={{ padding: '16px 24px', boxSizing: 'border-box', display: 'flex', alignItems: 'center', textAlign: 'end', backgroundColor: 'rgb(255, 255, 255)', border: '1px solid rgb(231, 231, 231)', borderRadius: '16px', margin: '0px 16px 2% 0px' }}>
              <span style={{ fontSize: '12px', fontWeight: 400, color: 'rgb(0, 0, 0)', margin: '0px 8px 0px 0px', lineHeight: 1 }}>Total
                Actual Cost</span><span style={{ fontSize: '16px', color: 'rgb(0, 0, 0)', fontWeight: 600 }}>{totalCost?.actualCost == null
                  ? "$ 0"
                  : "$" + " " + totalCost?.actualCost}</span></div>
            <div style={{ width: 'fit-content', padding: '16px 24px', boxSizing: 'border-box', alignItems: 'center', display: 'flex', textAlign: 'end', backgroundColor: 'rgb(255, 255, 255)', border: '1px solid rgb(231, 231, 231)', borderRadius: '16px', margin: '0px 0px 2%' }}>
              <span style={{ fontSize: '12px', fontWeight: 400, color: 'rgb(0, 0, 0)', margin: '0px 8px 0px 0px', lineHeight: 1 }}>Total
                Forecast Cost</span><span style={{ fontSize: '16px', color: 'rgb(0, 0, 0)', fontWeight: 600 }}>{totalCost?.forecastCost == null
                  ? "$ 0"
                  : "$" + " " + totalCost?.forecastCost}</span></div>
          </div>
          <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap', marginBottom: '2%' }}>
            <div style={{ width: '100%', padding: '1%', margin: '0px 1% 0px 0px', borderRadius: '14px', backgroundColor: 'rgb(255, 255, 255)', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <h3 style={{ fontSize: '15px', fontWeight: 'bold', color: 'rgb(0, 0, 0)', margin: '0px 0px 0.5rem' }}>Impact
                based on</h3>
              <div className="text-center">
                <ResponsiveContainer width={1350} height={400}>
                  <AreaChart width={1350} height={400} data={graphData}
                    margin={{ top: 20, right: 20, left: 20, bottom: 20 }}>
                    <defs>
                      <linearGradient id="colorUv1" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                      </linearGradient>
                      <linearGradient id="colorPv1" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                      </linearGradient>
                    </defs>
                    <XAxis dataKey="name" padding={{ left: 20, right: 20 }} tickLine={false} axisLine={false} tickSize={25} />
                    <YAxis
                      label={{
                        value: costFlag === true ? `` : `Cost`,
                        angle: -90,
                        position: { x: 0, y: 140 },
                        dy: 10
                      }} tickFormatter={(value: number) => `$${value}`} />
                    <CartesianGrid stroke="#ebebeb" vertical={false} strokeDasharray="4 4" />
                    <Tooltip />
                    <Area type="monotone" dataKey="actualCost" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv1)" />
                    <Area type="monotone" dataKey="forecastCost" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv1)" />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
              <div style={{ display: 'flex', textAlign: 'center', width: '100%', justifyContent: 'center' }}><span style={{ width: '10px', height: '10px', borderRadius: '2px', display: 'inline-block', marginRight: '6px', backgroundColor: 'rgb(213, 181, 255)' }} /><span style={{ fontSize: '12px', fontWeight: 500, color: 'rgb(176, 176, 176)', marginRight: '1rem', lineHeight: 1 }}>Actual
                Cost</span><span style={{ width: '10px', height: '10px', borderRadius: '2px', display: 'inline-block', marginRight: '6px', backgroundColor: 'rgb(71, 195, 60)', lineHeight: 1 }} /><span style={{ fontSize: '12px', fontWeight: 500, color: 'rgb(176, 176, 176)', marginRight: '1rem', lineHeight: 1 }}>Forecast
                  Cost</span></div>
            </div>
          </div>
          <div style={{ padding: '2%', backgroundColor: 'rgb(255, 255, 255)', borderRadius: '16px', margin: '1% 0px', display: 'flex', width: '100%', border: '0px', boxSizing: 'border-box', flexDirection: 'column' }}>
            <h1 style={{ fontSize: '16px', fontWeight: 600, marginTop: '0px' }}>Cloud Cost based on Usecases</h1>
            <div style={{ borderRadius: '14px', border: '1px solid rgb(222, 222, 222)', display: 'flex', width: '100%', flexDirection: 'column' }}>
              <table style={{ verticalAlign: 'middle', borderCollapse: 'collapse', width: '100%', color: 'rgb(33, 37, 41)' }}>
                <thead style={{ borderColor: 'inherit', borderWidth: '0px', backgroundColor: 'rgb(248, 242, 255)', fontSize: '14px' }}>
                  <tr>
                    <th style={{ textAlign: 'start', borderRadius: '14px 0px 0px', padding: '12px 18px', fontWeight: 600 }}>
                      Usecase</th>
                    <th style={{ fontWeight: 600, textAlign: 'start', padding: '12px 18px' }}>Provider</th>
                    <th style={{ fontWeight: 600, textAlign: 'start', padding: '12px 18px' }}>Location</th>
                    <th style={{ fontWeight: 600, textAlign: 'right', borderRadius: '0px 14px 0px 0px', padding: '12px 18px' }}>
                      Actual Cost</th>
                    <th style={{ fontWeight: 600, textAlign: 'right', borderRadius: '0px 14px 0px 0px', padding: '12px 18px' }}>
                      Forecasting Cost</th>
                  </tr>
                </thead>
                <tbody style={{ fontSize: '14px', color: 'rgb(69, 69, 69)', fontWeight: 400 }}>
                  {usecaseData.map((val: any, ind: any) => {
                    return (
                      <>
                        <tr>
                          <td style={{ padding: '12px 18px', fontWeight: 'bold' }}>{val.usecase_name}</td>
                          <td style={{ padding: '12px 18px', fontWeight: 'bold' }}>{val.provider}</td>
                          <td style={{ padding: '12px 18px', fontWeight: 'bold' }}>{val.location}</td>
                          <td style={{ padding: '12px 18px', textAlign: 'right', fontWeight: 'bold' }}>${val.usecase_actual}</td>
                          <td style={{ padding: '12px 18px', textAlign: 'right', fontWeight: 'bold' }}>${val.usecase_forecast}</td>
                        </tr>

                        <tr style={{ borderBottom: '1px solid rgb(222, 222, 222)' }}>
                          <td colSpan={5} style={{ padding: '12px 18px 18px' }}><span style={{ borderRadius: '14px', border: '1px solid rgb(222, 222, 222)', display: 'flex', width: '97%', boxSizing: 'border-box', marginLeft: '2%', marginRight: '1%' }}>
                            <table style={{ verticalAlign: 'middle', borderCollapse: 'collapse', width: '100%', color: 'rgb(33, 37, 41)' }}>
                              <thead style={{ borderColor: 'inherit', borderWidth: '0px', backgroundColor: 'rgb(248, 242, 255)', fontSize: '14px' }}>
                                <tr>
                                  <th style={{ padding: '12px 18px', textAlign: 'start', borderRadius: '14px 0px 0px', fontWeight: 600 }}>
                                    Service's</th>
                                  <th style={{ fontWeight: 600, padding: '12px 18px', textAlign: 'start' }}>
                                    Purpose</th>
                                  <th style={{ fontWeight: 600, padding: '12px 18px', textAlign: 'start' }}>
                                    SKU's</th>
                                  <th style={{ fontWeight: 600, padding: '12px 18px', textAlign: 'right' }}>
                                    Actual Cost</th>
                                </tr>
                              </thead>
                              <tbody>
                                {val.services.map((value: any, index: any) => {
                                  return (
                                    <tr style={{backgroundColor: `${index % 2===0?"":"#F6F6F6"}`}}>
                                      <td style={{ padding: '12px 18px' }}>{value.service_name === null || undefined ? "-" : value.service_name}</td>
                                      <td style={{ padding: '12px 18px' }}>{value.purpose === null || undefined ? "-" : value.purpose}</td>
                                      <td style={{ padding: '12px 18px' }}>{value.sku === null || undefined ? "-" : value.sku}</td>
                                      <td style={{ padding: '12px 18px', textAlign: 'right' }}>${value.actualCost === null || undefined ? "-" : value.actualCost}</td>
                                    </tr>
                                  )
                                })}

                              </tbody>
                            </table>
                          </span></td>
                        </tr>
                      </>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div
        className="d-flex"
        id="dashboard-content"
        style={{ display: "block" }}
      >
        <LeftsidePanel />
        <div className="content-container">
          {/* Page Title */}
          <div className="d-lg-flex d-block  justify-content-between mb-4">
            <h1 className="font-32 shadow-black font-bold text-nowrap mb-3">
              Cloud Cost
            </h1>
            <div className="d-md-flex d-block justify-content-none justify-content-lg-end custom-wrap">
              <div className="d-flex mb-3 mb-lg-0 align-items-center gap-4 cost-container me-2 text-nowrap">
                <span>
                  <img
                    src="image/money-bag-icon.svg"
                    alt="money-bag-icon"
                    className="cost-icon me-2"
                  />
                  <span className="font-12 font-regular custom-grey">
                    Actual Cost
                  </span>
                </span>
                <span className="font-16 font-semibold">
                  {totalCost?.actualCost == null
                    ? 0
                    : "$" + " " + totalCost?.actualCost}
                </span>
              </div>
              <div className="d-flex mb-3 mb-lg-0 align-items-center gap-4 cost-container me-2 text-nowrap">
                <span>
                  <img
                    src="image/time-is-money-icon.svg"
                    alt="money-bag-icon"
                    className="cost-icon me-2"
                  />
                  <span className="font-12 font-regular custom-grey">
                    Forecast Cost
                  </span>
                </span>
                <span className="font-16 font-semibold">
                  {" "}
                  {totalCost?.forecastCost == null
                    ? 0
                    : "$" + " " + totalCost?.forecastCost}
                </span>
              </div>
              <div
                className="dropdown me-2"
                hidden={costEstimationObject.filterType === "Custom Date Range"}
              >
                <button
                  className="btn btn-secondary dropdown-toggle sd-select"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  onClick={() => {
                    costEstimationObject.filterType === "Custom Date Range"
                      ? setShowFilter(true)
                      : setShowFilter(false);
                  }}
                >
                  {costEstimationObject.filterType}
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li
                    className="dropdown-item"
                    onClick={() => {
                      setCostEstimationObject({
                        ...costEstimationObject,
                        filterType: "Current Year",
                      });
                      setShowFilter(false);
                    }}
                  >
                    Current Year
                  </li>
                  <li
                    className="dropdown-item"
                    onClick={() => {
                      setCostEstimationObject({
                        ...costEstimationObject,
                        filterType: "Last Year",
                      });
                      setShowFilter(false);
                    }}
                  >
                    Last Year
                  </li>
                  <li
                    className="dropdown-item"
                    onClick={() => {
                      setCostEstimationObject({
                        ...costEstimationObject,
                        filterType: "Current Month",
                      });
                      setShowFilter(false);
                    }}
                  >
                    Current Month
                  </li>
                  <li
                    className="dropdown-item"
                    onClick={() => {
                      setCostEstimationObject({
                        ...costEstimationObject,
                        filterType: "Last Month",
                      });
                      setShowFilter(false);
                    }}
                  >
                    Last Month
                  </li>
                  <li
                    className="dropdown-item"
                    onClick={() => {
                      setCostEstimationObject({
                        ...costEstimationObject,
                        filterType: "Last 6 Months",
                      });
                      setShowFilter(false);
                    }}
                  >
                    Last 6 Months
                  </li>
                  <li
                    className="dropdown-item"
                    onClick={() => {
                      setCostEstimationObject({
                        ...costEstimationObject,
                        filterType: "Custom Date Range",
                      });
                      setShowFilter(true);
                    }}
                  >
                    Custom Date Range
                  </li>
                </ul>
              </div>

              <div
                className="dropdown me-2"
                hidden={costEstimationObject.filterType !== "Custom Date Range"}
              >
                <button
                  className="btn btn-secondary dropdown-toggle sd-select"
                  type="button"
                  onClick={() => {
                    costEstimationObject.filterType === "Custom Date Range"
                      ? setShowFilter(true)
                      : setShowFilter(false);
                  }}
                >
                  {costEstimationObject.filterType}
                </button>
                {showFilter && (
                  <ul
                    className={`dropdown-menu sd-custom-timeline px-0 pt-3 filter-width d-block align-custom-filter`}
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li className="h-100">
                      <div className="d-flex align-items-start container-width">
                        <div
                          className="nav flex-column nav-pills me-3 font-12"
                          id="v-pills-tab"
                          role="tablist"
                          aria-orientation="vertical"
                        >
                          <button
                            className="nav-link"
                            id="sd-Current-Year-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#sd-Current-Year"
                            type="button"
                            role="tab"
                            aria-controls="sd-Current-Year"
                            aria-selected="false"
                            onClick={() => {
                              setCostEstimationObject({
                                ...costEstimationObject,
                                filterType: "Current Year",
                              });
                              setShowFilter(false);
                            }}
                          >
                            Current Year
                          </button>
                          <button
                            className="nav-link"
                            id="sd-Current-Year-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#sd-Current-Year"
                            type="button"
                            role="tab"
                            aria-controls="sd-Current-Year"
                            aria-selected="false"
                            onClick={() => {
                              setCostEstimationObject({
                                ...costEstimationObject,
                                filterType: "Last Year",
                              });
                              setShowFilter(false);
                            }}
                          >
                            Last Year
                          </button>
                          <button
                            className="nav-link"
                            id="sd-Last-Year-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#sd-Last-Year"
                            type="button"
                            role="tab"
                            aria-controls="sd-Last-Year"
                            aria-selected="false"
                            onClick={() => {
                              setCostEstimationObject({
                                ...costEstimationObject,
                                filterType: "Current Month",
                              });
                              setShowFilter(false);
                            }}
                          >
                            Current Month
                          </button>
                          <button
                            className="nav-link"
                            id="sd-Last-Month-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#sd-Last-Month"
                            type="button"
                            role="tab"
                            aria-controls="sd-Last-Month"
                            aria-selected="false"
                            onClick={() => {
                              setCostEstimationObject({
                                ...costEstimationObject,
                                filterType: "Last Month",
                              });
                              setShowFilter(false);
                            }}
                          >
                            Last Month
                          </button>
                          <button
                            className="nav-link"
                            id="sd-Last-6-Months-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#sd-Last-6-Months"
                            type="button"
                            role="tab"
                            aria-controls="sd-Last-6-Months"
                            aria-selected="false"
                            onClick={() => {
                              setCostEstimationObject({
                                ...costEstimationObject,
                                filterType: "Last 6 Months",
                              });
                              setShowFilter(false);
                            }}
                          >
                            Last 6 Months
                          </button>
                          <button
                            className="nav-link active"
                            id="custom-datarange-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#custom-datarange"
                            type="button"
                            role="tab"
                            aria-controls="custom-datarange"
                            aria-selected="true"
                            onClick={() => {
                              setCostEstimationObject({
                                ...costEstimationObject,
                                filterType: "Custom Date Range",
                              });
                              setShowFilter(true);
                            }}
                          >
                            Custom Date Range
                          </button>
                        </div>
                        <div className="tab-content" id="v-pills-tabContent">
                          <div
                            className="tab-pane fade show active"
                            id="custom-datarange"
                            role="tabpanel"
                          >
                            <ConfigProvider
                              theme={{
                                token: {
                                  // Seed Token
                                  colorPrimary: "#753ced",
                                  borderRadius: 2,

                                  // Alias Token
                                  colorBgContainer: "#f6ffed",
                                },
                              }}
                            >
                              <Space direction="vertical" size={12}>
                                <RangePicker
                                  open={
                                    costEstimationObject.filterType ===
                                    "Custom Date Range" && showFilter
                                  }
                                  onChange={datePickerOnchange}
                                  dropdownClassName="set-picker-inset"
                                  format={"MM/DD/YYYY"}
                                />
                              </Space>
                            </ConfigProvider>
                          </div>
                        </div>
                      </div>
                      <div className="text-end p-2 border-top">
                        {/* <label className="font-12 color-black font-medium me-2">03/06/2024 - 04/04/2024</label> */}
                        <button
                          type="button"
                          className="btn secondary-btn font-12 font-semibold me-2"
                          onClick={() => {
                            setShowFilter(false);
                          }}
                        >
                          Reset
                        </button>
                        <button
                          type="button"
                          className="btn primary-btn font-12 font-semibold"
                          onClick={() => {
                            setCostEstimationObject({
                              ...costEstimationObject,
                              filterType: "Custom Date Range",
                              fromDate: filterFromDate,
                              toDate: filterToDate,
                            });
                            setShowFilter(false);
                          }}
                        >
                          Apply
                        </button>
                      </div>
                    </li>
                  </ul>
                )}
              </div>

              <button
                type="button"
                className="sd-action-icon me-2 mb-3 mb-lg-0"
              >
                <img
                  src="image/pdf-icon.svg"
                  alt="pdf-icon"
                  width="24px"
                  onClick={() => downloadPdf()}
                />
              </button>
              <button type="button" className="sd-action-icon mb-3 mb-lg-0">
                <img
                  src="image/xlx-icon.svg"
                  alt="xlx-icon"
                  width="24px"
                  onClick={() => downloadExcel()}
                />
              </button>
            </div>
          </div>
          {/* 1st Section - Starts here */}
          <div className="row mb-3">
            <div className="col-md-12 mb-4">
              <div className="sd-card h-100 custom-scroll-chart-cloud">
                {/* Widget Title */}
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h3 className="font-16 font-medium color-black mb-0">
                    Impact based on
                  </h3>
                </div>
                <div className="text-center">
                  <ResponsiveContainer width={1050} height={300}>
                    <AreaChart
                      width={1050}
                      height={450}
                      data={graphData}
                      margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
                    >
                      <defs>
                        <linearGradient
                          id="colorUv"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="5%"
                            stopColor="#8884d8"
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor="#8884d8"
                            stopOpacity={0}
                          />
                        </linearGradient>
                        <linearGradient
                          id="colorPv"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="5%"
                            stopColor="#82ca9d"
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor="#82ca9d"
                            stopOpacity={0}
                          />
                        </linearGradient>
                      </defs>
                      <XAxis dataKey="name" padding={{ left: 30, right: 15 }} tickLine={false} axisLine={false} tickSize={25} />
                      <YAxis
                        ticks={avgValues}
                        tickCount={0}
                        allowDataOverflow={false}
                        label={{
                          value: costFlag ? `` : `cost`,
                          angle: -90,
                          position: { x: 0, y: 90 },
                        }} axisLine={false} tickLine={false} tickFormatter={(value: number) => `$${value}`}

                      />
                      <CartesianGrid stroke="#ebebeb"
                        vertical={false}
                      />
                      <Tooltip content={<CustomTooltip />} />
                      <Area
                        type="monotone"
                        dataKey="actualCost"
                        stroke="#8884d8"
                        fillOpacity={1}
                        fill="url(#colorUv)"
                      />
                      <Area
                        type="monotone"
                        dataKey="forecastCost"
                        stroke="#82ca9d"
                        fillOpacity={1}
                        fill="url(#colorPv)"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                  {/*Graph component should be placed here*/}
                  <div className="mt-4">
                    <span className="purplev2-legend-style font-12 font-regular color-greyv1 me-3">
                      Actual Cost
                    </span>
                    <span className="green-legend-style font-12 font-regular color-greyv1">
                      Forecast Cost
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* 2nd Section - Starts here */}
          <div className="row mb-4">
            <div className="col-md-12 mb-4">
              <div className="sd-card h-100">
                {/* Widget Title */}
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h3 className="font-16 font-medium color-black mb-0">
                    Cloud Cost based on Usecases
                  </h3>
                </div>
                <div className="table-responsive theme-table custom bg-white ">
                  <table
                    className="table table-borderless mb-0"
                    id="usecase_parent"
                  >
                    <thead>
                      <tr>
                        <th className="text-nowrap">
                          Usecase
                          {sortDirection === "desc" && sortedBy ==="Usecase"?<span className="ms-1 cursor-pointer" onClick={()=>{UsecaseSort("Usecase","asc")}}>
                            <img
                              src="image/sort-arrow-down.svg"
                              alt="sort-arrow-down"
                            />
                          </span>:<span className="ms-1 cursor-pointer" onClick={()=>{UsecaseSort("Usecase","desc")}}>
                            <img
                              src="image/sort-arrow-up.svg"
                              alt="sort-arrow-down"
                            />
                          </span>}
                        </th>
                        <th className="text-nowrap">Provider</th>
                        <th className="text-nowrap">Location</th>
                        <th className="text-nowrap text-end">
                          Actual Cost
                          {sortDirection === "desc" && sortedBy ==="Actual Cost"?
                          <span className="ms-1 cursor-pointer" onClick={()=>{UsecaseSort("Actual Cost","asc")}}>
                              <img
                                src="image/sort-arrow-down.svg"
                                alt="sort-arrow-down"
                              />
                          </span>:
                          <span className="ms-1 cursor-pointer" onClick={()=>{UsecaseSort("Actual Cost","desc")}}>
                              <img
                                src="image/sort-arrow-up.svg"
                                alt="sort-arrow-down"
                              />
                            </span>}
                        </th>
                        <th className="text-nowrap text-end">
                          Forecasting Cost
                          {sortDirection === "desc" && sortedBy ==="Forecasting Cost"?
                            <span className="ms-1 cursor-pointer" onClick={()=>{UsecaseSort("Forecasting Cost","asc")}}>
                              <img
                                src="image/sort-arrow-down.svg"
                                alt="sort-arrow-down"
                              />
                            </span>:
                            <span className="ms-1 cursor-pointer" onClick={()=>{UsecaseSort("Forecasting Cost","desc")}}>
                              <img
                                src="image/sort-arrow-up.svg"
                                alt="sort-arrow-down"
                              />
                            </span>}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {usecaseData.map((val: any, index: any) => {

                        return (
                          <>
                            <tr
                              className="accordion-table-row font-bold font-14 "
                            >
                              <td className="text-nowrap p-3">
                                <span className="d-flex align-items-center gap-3">
                                  <img
                                    src="image/table-accordion-arrow.svg"
                                    alt="table-accordion-arrow"
                                    className={`cursor-pointer table-accordion-arrow ${index !== 0 ? "collapsed" : ""}`}
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#usecase-${index}`}
                                    aria-expanded={`${index === 0 ? true : false}`}
                                  />
                                  <span className={`ac-heading cursor-pointer table-accordion-arrow ${index !== 0 ? "collapsed" : ""}`}
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#usecase-${index}`}
                                    aria-expanded={`${index === 0 ? true : false}`}>
                                    {val.usecase_name}
                                  </span>
                                </span>
                              </td>
                              <td className="text-nowrap font-14 font-bold">
                                {val.provider}
                              </td>
                              <td className="text-nowrap font-14 font-bold">
                                {val.location}
                              </td>
                              <td className="text-nowrap font-14 font-bold text-end">
                                ${val.usecase_actual}
                              </td>
                              <td className="text-nowrap font-14 font-bold text-end">
                                ${val.usecase_forecast}
                              </td>
                            </tr>
                            <tr
                              className={`collapse ${
                                index === 0 ? "show" : ""
                              } table-accordion-body`}
                              id={`usecase-${index}`}
                              data-bs-parent="#usecase_parent"
                            >
                              <td colSpan={5} className="custom-padding">
                                <span className="table-responsive theme-table regular bg-white d-inline-block w-100">
                                  <table className="table table-borderless mb-0 align-middle">
                                    <thead>
                                      <tr>
                                        <th className="text-nowrap">
                                          Service's
                                          {sortDirection === "desc" && sortedBy ==="service_name"?
                                            <span className="ms-1 cursor-pointer" >
                                              <img
                                                src="image/sort-arrow-down.svg"
                                                alt="sort-arrow-down"
                                              onClick={()=>{serviceSort(index,"service_name","asc")}}
                                              />
                                          </span>:
                                            <span className="ms-1 cursor-pointer" >
                                              <img
                                                src="image/sort-arrow-up.svg"
                                                alt="sort-arrow-down"
                                              onClick={()=>{serviceSort(index,"service_name","desc")}}
                                              />
                                            </span>}
                                        </th>
                                        <th className="text-nowrap">Purpose</th>
                                        <th className="text-nowrap">SKU's</th>
                                        <th className="text-nowrap text-end">
                                          Actual Cost
                                          {sortDirection === "desc" && sortedBy ==="actualCost"?
                                            <span className="ms-1 cursor-pointer" >
                                              <img
                                                src="image/sort-arrow-down.svg"
                                                alt="sort-arrow-down"
                                                onClick={()=>{serviceSort(index,"actualCost","asc")}}
                                              /></span>:
                                            <span className="ms-1 cursor-pointer"  onClick={()=>{serviceSort(index,"actualCost","desc")}}>
                                              <img
                                                src="image/sort-arrow-up.svg"
                                                alt="sort-arrow-down"
                                              />
                                            </span>}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {val.services.map(
                                        (value: any, index1: any) => {
                                          return (
                                            <tr>
                                              <td>{value.service_name === null || undefined ? "-" : value.service_name}</td>
                                              <td>{value.purpose === null || undefined ? "-" : value.purpose}</td>
                                              <td>{value.sku === null || undefined ? "-" : value.sku}</td>
                                              <td className="text-end">
                                                ${value.actualCost=== null || undefined ? "-" : value.actualCost}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  </table>
                                </span>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {sessionExpired && <SessionExpiredPopup />}
        </div>
      </div>
    </div>
  );
};

export default CloudCost;
