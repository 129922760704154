//PS_RP_1.0
//Imports
import React, { useState } from 'react';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { API } from '../apiservices/resetPassword/api'
import { useNavigate } from 'react-router';
import { encryptPassword, mailId } from '../constant';
import PopUp from './Popup';
import { logout } from '../apiservices/signIn/api';
import SessionExpiredPopup from './sessionExpired';


const UpdatePassword = () => {
  // ... (Initialization and state management code)
  //PS_RP_1.2
  //Variable declaration
  const [updatePasswordObj, setupdatePasswordObj] = useState({ currentPassword: '', newPassword: '', confirmPassword: '' });
  const token = new URLSearchParams(window.location.search).get('token');
  const [errorObj, seterrorObj] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: ""
  });
  const [showSuccess, setshowSuccess] = useState(false)
  const [showEyeCurrent, setShowEyeCurrent] = useState(false);
  const [showEyeNew, setShowEyeNew] = useState(false);
  const [showEyeConfirm, setShowEyeConfirm] = useState(false);
  const navigate = useNavigate()
  const [showPopup, setShowPopup] = useState(false);    
  const [sessionExpired, setsessionExpired] = useState(false)
  const [errorType, seterrorType] = useState("Error");
  const [errorMessage, seterrorMessage] = useState("Something Went Wrong!!!");

  //PS_RP_1.3 - 23
  //On clicking update password this function is triggered
  const ResetPassword = async () => {
    // Step 3: Define updatePasswordObj with values from user input
    const reqmailId = new URLSearchParams(window.location.search).get('mailId') ? new URLSearchParams(window.location.search).get('mailId') : sessionStorage.getItem("useremail");
    let temperrorObj: any = {
      currentPassword: "",
      newPassword: "",
      confirmPassword: ""
    };
    seterrorObj(temperrorObj)
    const Passwordregex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,20}$/

    // Step 4: Validate that the fields are not empty and passwords match
    if (!updatePasswordObj.newPassword || (!token && !updatePasswordObj.currentPassword) || !updatePasswordObj.confirmPassword || updatePasswordObj.newPassword !== updatePasswordObj.confirmPassword || !Passwordregex.test(updatePasswordObj.newPassword)) {
      if (!updatePasswordObj.newPassword)
        temperrorObj.newPassword = "This field is mandatory"
      if (!Passwordregex.test(updatePasswordObj.newPassword) && updatePasswordObj.newPassword)
        temperrorObj.newPassword = "Enter a valid password"
      if (!token && !updatePasswordObj.currentPassword)
        temperrorObj.currentPassword = "This field is mandatory"
      if (!updatePasswordObj.confirmPassword)
        temperrorObj.confirmPassword = "This field is mandatory"
      if ((updatePasswordObj.newPassword !== updatePasswordObj.confirmPassword) && updatePasswordObj.confirmPassword)
        temperrorObj.confirmPassword = "Confirm password is not matches with the new password "
      seterrorObj(temperrorObj)
      return;
    }
    // Step 5: resetObj is declared with keys mailId and password
    let resetObj: any = {
      mailId: reqmailId,
      token: token,
      oldPassword: updatePasswordObj.currentPassword,
      newpassword: updatePasswordObj.newPassword,
    };

    // Step 6: Encrypt the password
    resetObj.oldPassword = encryptPassword(resetObj.oldPassword);
    resetObj.newpassword = encryptPassword(resetObj.newpassword);

    const logoutSession =async()=>{
      try{
        const logoutUser = await logout();
        if(logoutUser.status===200) 
        console.log("API Failed")
      }
      catch(e){
        console.log(e)
      }
      
    }
    // Steps 10.1 and 11.1: If they match, call the API to update the password
    try {
      const updatePasswordResponse = await API.resetPassword(resetObj);
      // Step 21.1: Check if response is successful
      if (updatePasswordResponse.status === 200) {
        // Navigate to dashboard
        if(!token){
          logoutSession()
        }
        sessionStorage.clear();
        setshowSuccess(true)
      }
      else if(updatePasswordResponse.status === 400){
        if(updatePasswordResponse.error === "Password is incorrect")
        seterrorObj({...temperrorObj,currentPassword:updatePasswordResponse.error})
        else if(updatePasswordResponse.error === "New password cannot be the same as the current password.")
        seterrorObj({...temperrorObj,newPassword:updatePasswordResponse.error})
        else
        seterrorObj({...temperrorObj,confirmPassword:updatePasswordResponse.error})
        
      }else if(updatePasswordResponse.status ===401){
        setsessionExpired(true);
    }
      else {
        // Step 22.1: Display an error message
        setShowPopup(true);
        if (updatePasswordResponse.error) {
          seterrorMessage(updatePasswordResponse.error)
        }
      }    
    } catch (error) {
      // Step 23: Display an error message in case of an exception
      setShowPopup(true);
    }
  };


  return (
    <div className="container-fluid">
      <div className="row align-items-center">
        {/* Left Container */}
        {/* Left Container - Reset Password */}
        {!showSuccess && <div className="col-md-6 respon-container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <h1 className="font-semibold color-black font-32  mb-4">Reset your Password</h1>
              {!token && <div className="custom-formfield mt-4 ">
                <label className="w-100 mb-1 font-16 font-medium" htmlFor="new-password">Current Password</label>
                <span className="position-relative">
                  <input type={showEyeCurrent ? 'text' : "password"} className="form-control font-14 pe-5" placeholder="Enter your Current Password" id="current-password" minLength={8}
                    maxLength={12}
                    value={updatePasswordObj.currentPassword}
                    onChange={(e) =>
                      setupdatePasswordObj({
                        ...updatePasswordObj,
                        currentPassword: e.target.value,
                      })
                    } />
                  <img src="image/password-eye.svg" alt="Open-Password" onClick={() => { setShowEyeCurrent(!showEyeCurrent) }} className=" position-absolute password-position" />
                </span>
                <div className="pt-1">
                  {errorObj.currentPassword !== "" ? (
                    <span className="color-red font-12 font-regular">
                      {errorObj.currentPassword}
                    </span>
                  ) : ""
                  }
                </div>
              </div>}
              <div className="custom-formfield mt-4 ">
                <label className="w-100 mb-1 font-16 font-medium" htmlFor="new-password">New Password</label>
                <span className="position-relative">
                  <input type={showEyeNew ? 'text' : "password"} className="form-control font-14 pe-5" placeholder="Enter your New Password" id="new-password" minLength={8}
                    maxLength={12}
                    value={updatePasswordObj.newPassword}
                    onChange={(e) =>
                      setupdatePasswordObj({
                        ...updatePasswordObj,
                        newPassword: e.target.value,
                      })
                    } />
                  <img src="image/password-eye.svg" alt="Open-Password" onClick={() => { setShowEyeNew(!showEyeNew) }} className=" position-absolute password-position" />
                </span>
                <div className="pt-1">
                  {errorObj.newPassword !== "" ? (
                    <span className="color-red font-12 font-regular">
                      {errorObj.newPassword}
                    </span>
                  ) : ""
                  }
                </div>
              </div>
              <div className="custom-formfield mt-4 ">
                <label className="w-100 mb-1 font-16 font-medium" htmlFor="confirm-password">Confirm Password</label>
                <span className="position-relative">
                  <input type={showEyeConfirm ? 'text' : "password"} className="form-control font-14 pe-5" placeholder="Re-enter Password" id="confirm-password" minLength={8}
                    maxLength={12}
                    value={updatePasswordObj.confirmPassword}
                    onChange={(e) =>
                      setupdatePasswordObj({
                        ...updatePasswordObj,
                        confirmPassword: e.target.value,
                      })
                    } />
                  <img src="image/password-eye.svg" alt="Open-Password" onClick={() => { setShowEyeConfirm(!showEyeConfirm) }} className=" position-absolute password-position" />
                </span>
                <div className="pt-1">
                  {errorObj.confirmPassword !== "" ? (
                    <span className="color-red font-12 font-regular">
                      {errorObj.confirmPassword}
                    </span>
                  ) : ""
                  }
                </div>
              </div>
              <button className="btn btn-primary custom-btn w-100 mt-5" onClick={() => ResetPassword()}>Update Password</button>
            </div>
          </div>
        </div>}
        {showSuccess && <div className="col-md-6 respon-container">
          <div className="row justify-content-center">
            <div className="col-md-8 text-center">
              <img src="image/Successful-tick.svg" alt="Successful-tick" />
              <h1 className="font-semibold color-black font-24 mt-3">Password Changed!</h1>
              <p className="descrip-label font-16 mb-4">Your password has been changed successfully</p>
              <button className="btn btn-primary custom-btn w-100 mt-2" onClick={() => { navigate('/') }}>Back to Sign In</button>
            </div>
          </div>
        </div>}
        {/* Right Container */}
        <div className="col-md-6 p-3">
          <div className="login-right-container">
            <div className="d-flex align-items-center justify-content-center flex-column p-4">
              {/* Image Element */}
              <div className="text-left w-100">
                <img src="image/SuperDesk-logo.svg" alt="superdesk-logo" className="login-logo mb-4" />
              </div>
              <img src="image/Login-image.svg" alt="Login-Image" className="login-image-style my-4" />
              {/* Carousel Element - Starts here */}
              <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators custom-carousel">
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" />
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={1} aria-label="Slide 2" />
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={2} aria-label="Slide 3" />
                </div>
                <div className="carousel-inner text-center p-5">
                  <div className="carousel-item active">
                    <h1 className="font-24 font-medium color-white">Incident Management Automation</h1>
                    <p className="font-16 color-greyv1 font-light px-5">Automate your entire Incident Management process with SuperDesk right from intake to triage to Service Desk centric resolution.</p>
                  </div>
                  <div className="carousel-item">
                    <h1 className="font-24 font-medium color-white">Incident Management Automation</h1>
                    <p className="font-16 color-greyv1 font-light px-5">Automate your entire Incident Management process with SuperDesk right from intake to triage to Service Desk centric resolution.</p>
                  </div>
                  <div className="carousel-item">
                    <h1 className="font-24 font-medium color-white">Incident Management Automation</h1>
                    <p className="font-16 color-greyv1 font-light px-5">Automate your entire Incident Management process with SuperDesk right from intake to triage to Service Desk centric resolution.</p>
                  </div>
                </div>
              </div>
              {/* Carousel Element - Ends here */}
            </div>
          </div>
        </div>
      </div>
      {sessionExpired && <SessionExpiredPopup/>}
    </div>
    // <div>

    //   {/* ... (Form elements and update password button event handling) */}
    //   <button onClick={updatePassword}>Update Password</button>
    // </div>
  );
};

export default UpdatePassword