import { Client } from '../../client'; // hypothetical path to Client.ts
// hypothetical path to Client.ts

// PS_SI_1.11
// API call for sign In
export const API = {
  logInDetailsObj: async (credentials: any): Promise<any> => {
    return Client("post","/signIn", credentials);
  }
};

export const logout = async () => {
  return await Client('POST', '/logout', {});
};