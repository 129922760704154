import { useState } from "react"
import { useNavigate } from "react-router-dom"

const SessionExpiredPopup = () => {
  const [sessionExpired,setsessionExpired] = useState(false)
  const navigate = useNavigate()
    return (
      <div className="body-bg model open">
    <div className="modal d-block fade show" id="add-audit" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static">
        <div className="modal-dialog audit-custom-popup-width">
          <div className="modal-content session-expired pt-5 px-2">
            {/*modal content starts here*/}
            <div className="modal-body border-0 font-16 px-4 pb-0 pt-0">
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="text-center">
                    <img src="image/error-popup-icon.svg" className="time-icon mb-4" />
                    <h5 className="font-20 font-semibold">Alert!</h5>
                    <p className="font-regular font-18 primary-textcolor">Your session has been timed out.</p>
                  </div>
                </div>
              </div>           
            </div>
            <div className="modal-footer border-0 py-3 mb-3 px-4 d-flex justify-content-center">
              <button type="button" className="popup-btn font-14 font-medium py-2" data-bs-dismiss="modal" onClick={() => navigate('/')}>OK</button>
            </div>
            {/*modal content ends here*/}
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
      </div>
      )
}
export default SessionExpiredPopup