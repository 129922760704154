import axios from 'axios';
import { Client } from '../../client'; // hypothetical path to Client.ts
 // Assuming Client.ts provides this function
//PS_HR_1.25 - 33
//API call to cases data
export const getQuestions = async (questionsReqObj:any) => {
  return await Client('POST', '/hrsdQuestions', questionsReqObj);
};
//PS_HR_1.40 - 48
//API call to cases data
export const getIncidents = async (incidentReqObj:any) => {
    return await Client('POST', '/hrsdIncidents', incidentReqObj);
  };
//PS_HR_1.10 - 18
//API call to cases data
  export const getCases = async (casesReqObj:any) => {
    return await Client('POST', '/hrsdCases', casesReqObj);
  };