import axios from 'axios';
import { Client } from '../../client'; // hypothetical path to Client.ts
 // Assuming Client.ts provides this function
 //PS_IT_1.37 - 45
//API call to get Requests data
export const getRequests = async (questionsReqObj:any) => {
  return await Client('POST', '/itopsRequests', questionsReqObj);
};
 //PS_IT_1.52 - 60
//API call to get Requests data
export const getIncidents = async (incidentReqObj:any) => {
    return await Client('POST', '/itopsIncidents', incidentReqObj);
  };
//PS_IT_1.10 - 18
//API call to get cases data
export const getCases = async (casesReqObj:any) => {
return await Client('POST', '/itopsCases', casesReqObj);
};
//PS_IT_1.22 - 29
//API call to get Playbacks data
export const getPlaybacks = async (playbacksReqObj:any) => {
return await Client('POST', '/itopsPlayback', playbacksReqObj);
};