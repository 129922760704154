import axios from 'axios';
import { Client } from '../../client'; // hypothetical path to Client.ts
 // Assuming Client.ts provides this function

//PS_CS_1.9 - 18
//API call to get questions data
export const getQuestions = async (questionsReqObj:any) => {
  return await Client('POST', '/customerServicequestions', questionsReqObj);
};

//PS_CS_1.24 - 33
//API call to get Incidents data
export const getIncidents = async (incidentReqObj:any) => {
    return await Client('POST', '/customerServiceIncidents', incidentReqObj);
  };
//PS_CS_1.39 - 48
//API call to get Impacts data
export const getImpacts = async (impactsReqObj:any) => {
  return await Client('POST', '/customerServiceImpacts', impactsReqObj);
};