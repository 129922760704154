import axios from "axios";
import { Client } from '../../client'; 


export const getApiTotalCost = async(cosEstimationObject :any)=>{
    return await Client('POST','/getTotalAndForecastCost', cosEstimationObject);
}

export const getApiGraphData = async(costEstimationObject :any) =>{
    return await Client('POST','/getGraphData', costEstimationObject);
}


export const getApiUsecase = async (costEstimationObject :any) =>{
    return await Client('POST','/getServiceTable' , costEstimationObject);
} 

export const getApiService = async (costEstimationObject : any) => {
    return await Client('POST','/serviceTableAccordian', costEstimationObject);
}