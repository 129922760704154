import axios from 'axios';

export const Client = async (method: any, endpoint: any, body: any) => {
  const authToken = sessionStorage.getItem("jwt")

  const url = `https://app.superdesk.ai${endpoint}`;
  // const url = `http://localhost:8000${endpoint}`
  let header: any = {
    'Content-Type': 'application/json'
  };
  if (endpoint === '/updatePassword') {
    header = {
      'Content-Type': 'application/json',
      "Authorization": authToken ? `Bearer ${authToken}` : `Bearer ${body.token}`
    }
    body = {
      ...body, ["role"]: sessionStorage.getItem("role")
    }
  }
  else if (endpoint === '/signIn' || endpoint === '/resetPassword') {
    header = {
      'Content-Type': 'application/json'
    }
  }
  else {
    header = {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${authToken}`
    }
    body = {
      ...body, ["mailId"]: sessionStorage.getItem("useremail"), ["role"]: sessionStorage.getItem("role")
    }
  }
  // const encryptedBody = encryptDataFunction(body)
  const requestData = {
    method: method,
    url: url,
    headers: header,
    data: body,
  };

  try {
    const { data } = await axios(requestData);
    return data;
  } catch (error) {
    console.error('Failed to fetch API:', error);

  }
};
