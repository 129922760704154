import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import SignInForm from './Components/signIn';
import ForgetPassword from './Components/forgetPassword';
import UpdatePassword from './Components/updatePassword';
import CustomerService from './Components/customerService';
import HRSDDashboard from './Components/hrsd';
import ITOPSDashboard from './Components/itops';
import ErrorPage from './Components/404Page';
import CloudCost from "./Components/cloudCost";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SignInForm />}></Route>
        <Route path="/Forgetpassword" element={<ForgetPassword />}></Route>
        <Route path="/resetPasswordHub" element={<UpdatePassword />}></Route>
        <Route path="/customerService" element={<CustomerService />}></Route>
        <Route path="/hrsd" element={<HRSDDashboard />}></Route>
        <Route path="/itops" element={<ITOPSDashboard />}></Route>
        <Route path="*" element={<ErrorPage />}></Route>
        <Route path="/ErrorPage" element={<ErrorPage />}></Route>
        <Route path="/cloudCost" element={<CloudCost />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
