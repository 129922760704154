import { Client } from '../../client'; // hypothetical path to Client.ts
//PS_RP_1.12 - 20
//API call to reset the password
// export const resetPassword = async (resetObj: any) => {
//     // Step 12.1: Prepare API call with necessary parameters
//     const endpoint = '/updatePassword';
//     const method = 'POST';
//     const apiResponse = await Client(method, endpoint, resetObj);
//     return apiResponse;
//   };
  export const API = {
    resetPassword: async (resetObj: any): Promise<any> => {
      return Client("post",'/updatePassword', resetObj);
    }
  };