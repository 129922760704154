//PS_HR_1.1
//Imports
import React, { useEffect, useState } from 'react';
import { ConfigProvider, DatePicker, Space } from 'antd';
import { getIncidents, getCases, getQuestions } from '../apiservices/hrsd/api'; // adjust the import path accordingly
import { useNavigate } from 'react-router-dom';
import LeftsidePanel from './leftsidePanel';
import ExcelJS from 'exceljs'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { role } from '../constant';
import { Loader } from './Loader';
import PopUp from './Popup';
import SessionExpiredPopup from './sessionExpired';
const HRSDDashboard = () => {
  // State variables
  const dashboardEntitlement = sessionStorage.getItem("role") === "Admin" ? "HRSD" : sessionStorage.getItem("role");
  //PS_HR_1.2
  //Request object declarations
  const [incidentReqObj, setIncidentReqObj] = useState({
    filterType: 'Current Month',
    fromDate: '',
    toDate: '',
    dashboardEntitlement: dashboardEntitlement
  });
  const [questionsReqObj, setQuestionsReqObj] = useState({
    issueType: 'Solved',
    requestsType: 'Solved',
    dashboardEntitlement: dashboardEntitlement
  });

  //PS_HR_1.6
  //declarations
  const navigate = useNavigate()
  const [filterCategory, setfilterCategory] = useState("All Categories")
  const [showTopUnresolvedCasesPopup, setshowTopUnresolvedCasesPopup] = useState(false)
  const [showLoader, setshowLoader] = useState(false)
  const [showemployeePopup, setshowemployeePopup] = useState(false)
  const [showfaqPopup, setshowfaqPopup] = useState(false)
  const [showissuesPopup, setshowissuesPopup] = useState(false)
  const [showrequestsPopup, setshowrequestsPopup] = useState(false)
  const [sessionExpired, setsessionExpired] = useState(false)
  const [showViewPopup, setshowViewPopup] = useState(false)
  const [viewQuestion, setviewQuestion] = useState("")
  const [viewTitle, setviewTitle] = useState("")
  const [viewJustification, setviewJustification] = useState("")
  const [viewCategory, setviewCategory] = useState("")
  const { RangePicker } = DatePicker;
  const [showPopup, setShowPopup] = useState(false);
  const [errorType, seterrorType] = useState("Error");
  const [errorMessage, seterrorMessage] = useState("Something Went Wrong!!!");
  const [showFilter, setShowFilter] = useState(true);
  const [filterFromDate, setFilterFromDate] = useState("")
  const [filterToDate, setFilterToDate] = useState("")
  //PS_HR_1.3
  //cases object declaration
  const [cases, setCases] = useState({
    topUnresolvedCases: [{
      questions: '',
      justification: '',
      category: '',
    }],
    employeeSatisfactionInsights: [{
      cases: '',
      Feedback: '',
    }]
  });

  //PS_HR_1.4
  //Incidents object declaration
  const [incidents, setIncidents] = useState({
    averageResponseTime: {
      time: "",
      percentageVariation: 0,
      valueChange: ""
    },
    noOfResolvedIncidents: {
      incidentResolved: 0,
      totalIncidents: 0,
      percentageVariation: 0,
      valueChange: ""
    },
    averageResolutionTime: {
      time: "",
      percentageVariation: 0,
      valueChange: ""
    }
  });

  //PS_HR_1.5
  //Questions object declaration
  const [questions, setQuestions] = useState({
    faq: [{
      questions: '',
      answers: '',
      timeWithSuperDesk: '',
      timeWithoutSuperDesk: ''
    }],
    issues: [{
      questions: '',
      answers: '',
      timeWithSuperDesk: '',
      timeWithoutSuperDesk: ''
    }],
    requests: [{
      questions: '',
      answers: '',
      timeWithSuperDesk: '',
      timeWithoutSuperDesk: ''
    }]
  });

  const topUnresolvedQuestionsPopupData = filterCategory === "All Categories" ? cases.topUnresolvedCases : cases.topUnresolvedCases.filter(item => item.category === filterCategory);

  // Fetch cases on component mount
  //PS_HR_1.7
  //useEffect
  useEffect(() => {
    let role=sessionStorage.getItem("role")
    if(role !== "Admin" && role!=="HRSD"){
      navigate('/ErrorPage')
      return
  }
    //PS_HR_1.8
    //To get cases data
    const loadCases = async () => {
      setshowLoader(true)
      try {
        const casesResponse = await getCases({ dashboardEntitlement: dashboardEntitlement }); // Assuming this function is properly implemented to make an API call
        if (casesResponse.status === 200) {
          setCases(casesResponse);
        }else if(casesResponse.status ===401){
          setsessionExpired(true);
      } else {
          setShowPopup(true);
        }
      } catch (error: any) {
        setShowPopup(true);
      }
      setshowLoader(false)

    };

    loadCases();
  }, []);
  // Fetch questions based on dependency changes
  //PS_HR_1.22
  //UseEffect
  useEffect(() => {
    //PS_HR_1.23 - 34
    //To get data for questions
    const loadQuestions = async () => {
      setshowLoader(true)
      try {
        const questionsResponse = await getQuestions(questionsReqObj);
        if (questionsResponse.status === 200) {
          setQuestions(questionsResponse);
        } else if(questionsResponse.status ===401){
          setsessionExpired(true);
      }else {
          setShowPopup(true);
        }
      } catch (error: any) {
        setShowPopup(true);
      }
      setshowLoader(false)
    };
    loadQuestions();

  }, [questionsReqObj]);

  // Fetch incidents based on dependency changes
  //PS_HR_1.37
  //Incidents useEffect
  useEffect(() => {
    //PS_HR_1.38 - 51
    //Incidents useEffect
    const loadIncidents = async () => {
      setshowLoader(true)
      try {
        const incidentsResponse = await getIncidents(incidentReqObj);
        if (incidentsResponse.status === 200) {
          setIncidents(incidentsResponse);
        } else if(incidentsResponse.status ===401){
          setsessionExpired(true);
      }else {
          setShowPopup(true);
        }
      } catch (error: any) {
        setShowPopup(true);
      }
      setshowLoader(false)
    };

    if (incidentReqObj.filterType) {
      loadIncidents();
    }
  }, [incidentReqObj]);
  const colors = ['#E0FF00', '#1E2029', '#9746FF', '#707070', '#ffffff'];
  const randomIndex = Math.floor(Math.random() * colors.length);
  // Handle change password navigation
  //PS_HR_1.56 - 61
  //To download excel
  const downloadExcel = async () => {

    try {

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Sheet1');
      let exportToExcel = [['#Resolved Incidents', 'Avg. Response Time', 'Avg. Resolution Time']]
      let val = [incidents.noOfResolvedIncidents.incidentResolved != null ? incidents.noOfResolvedIncidents.incidentResolved.toString() : "0", incidents.averageResponseTime.time ? (incidents.averageResponseTime.time.toString().split(':')[0] === "0.0 hr" && incidents.averageResponseTime.time.toString().split(':')[1] === " 0.0 mins" ? incidents.averageResponseTime.time.toString().split(':')[2] : incidents.averageResponseTime.time.toString().split(':')[0] === "0.0 hr" && incidents.averageResponseTime.time.toString().split(':')[1] !== " 0.0 mins" ? incidents.averageResponseTime.time.toString().split(':')[1] : incidents.averageResponseTime.time.toString().split(':')[0] !== "0.0 hr" ? incidents.averageResponseTime.time.toString().split(':')[0] : " 0.0 secs") : "0 secs", incidents.averageResolutionTime.time ? (incidents.averageResolutionTime.time.toString().split(':')[0] === "0.0 hr" && incidents.averageResolutionTime.time.toString().split(':')[1] === " 0.0 mins" ? incidents.averageResolutionTime.time.toString().split(':')[2] : incidents.averageResolutionTime.time.toString().split(':')[0] === "0.0 hr" && incidents.averageResolutionTime.time.toString().split(':')[1] !== " 0.0 mins" ? incidents.averageResolutionTime.time.toString().split(':')[1] : incidents.averageResolutionTime.time.toString().split(':')[0] !== "0.0 hr" ? incidents.averageResolutionTime.time.toString().split(':')[0] : " 0.0 secs") : "0 secs"]
      exportToExcel.push(val)
      val = ['Issues SuperDesk Solved']
      exportToExcel.push(val)
      val = ['Issues', 'SuperDesk Response', 'Time to Resolve without SuperDesk', 'Time to Resolve with SuperDesk']
      exportToExcel.push(val)
      questions.issues.map((value: any, index: any) => {
        val = [value.questions, value.answers, value.timeWithSuperDesk, value.timeWithoutSuperDesk]
        exportToExcel.push(val)
      })
      val = ['Issues SuperDesk Assisted']
      setQuestionsReqObj({ ...questionsReqObj, issueType: "Assited" })
      exportToExcel.push(val)
      val = ['Issues', 'SuperDesk Response', 'Time to Resolve without SuperDesk', 'Time to Resolve with SuperDesk']
      exportToExcel.push(val)
      questions.issues.map((value: any, index: any) => {
        val = [value.questions, value.answers, value.timeWithSuperDesk, value.timeWithoutSuperDesk]
        exportToExcel.push(val)
      })
      setQuestionsReqObj({ ...questionsReqObj, issueType: "Solved" })
      val = ['Requests SuperDesk Solved']
      exportToExcel.push(val)
      val = ['Requests', 'SuperDesk Response', 'Time to Resolve without SuperDesk', 'Time to Resolve with SuperDesk']
      exportToExcel.push(val)
      questions.requests.map((value: any, index: any) => {
        val = [value.questions, value.answers, value.timeWithSuperDesk, value.timeWithoutSuperDesk]
        exportToExcel.push(val)
      })
      val = ['Requests SuperDesk Assisted']
      setQuestionsReqObj({ ...questionsReqObj, requestsType: "Assited" })
      exportToExcel.push(val)
      val = ['Requests', 'SuperDesk Response', 'Time to Resolve without SuperDesk', 'Time to Resolve with SuperDesk']
      exportToExcel.push(val)
      questions.issues.map((value: any, index: any) => {
        val = [value.questions, value.answers, value.timeWithSuperDesk, value.timeWithoutSuperDesk]
        exportToExcel.push(val)
      })
      setQuestionsReqObj({ ...questionsReqObj, requestsType: "Solved" })
      val = ['Unresolved SuperDesk Cases']
      exportToExcel.push(val)
      val = ['Questions', 'Justification', 'Category']
      exportToExcel.push(val)
      cases.topUnresolvedCases.map((value: any, index: any) => {
        val = [value.questions, value.justification, value.category]
        exportToExcel.push(val)
      })
      val = ['Employee Satisfaction Insights']
      exportToExcel.push(val)
      val = ['Cases', 'Feedback']
      exportToExcel.push(val)
      cases.employeeSatisfactionInsights.map((value: any, index: any) => {
        val = [value.cases, value.Feedback]
        exportToExcel.push(val)
      })
      val = ['FAQ']
      exportToExcel.push(val)
      val = ['Questions', 'Answers', 'Time to Resolve without SuperDesk', 'Time to Resolve with SuperDesk']
      exportToExcel.push(val)
      questions.faq.map((value: any, index: any) => {
        val = [value.questions, value.answers, value.timeWithSuperDesk, value.timeWithoutSuperDesk]
        exportToExcel.push(val)
      })
      // Add data to the worksheet
      exportToExcel.forEach((rowData) => {
        worksheet.addRow(rowData);
      });
      worksheet.eachRow((row:any) => {
        row.eachCell((cell:any) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        });
      });
      worksheet.eachRow((row:any) => {
        row.eachCell((cell:any) => {
          cell.alignment = { wrapText: true };
        });
      });

      worksheet.columns.forEach((column:any) => {
        column.width = 50; // You can set any desired width here
      });
      const targetRowDataList = [
        ['#Resolved Incidents', 'Avg. Response Time', 'Avg. Resolution Time'],
        ['Issues SuperDesk Solved'],
        ['Issues SuperDesk Assisted'],
        ['Requests', 'SuperDesk Response', 'Time to Resolve without SuperDesk', 'Time to Resolve with SuperDesk'],
        ['Issues', 'SuperDesk Response', 'Time to Resolve without SuperDesk', 'Time to Resolve with SuperDesk'],
        ['Requests SuperDesk Solved'],
        ['Requests SuperDesk Assisted'],
        ['Unresolved SuperDesk Cases'],
        ['Questions', 'Justification', 'Category'],
        ['Employee Satisfaction Insights'],
        ['Cases', 'Feedback'],
        ['Resolved Incidents', 'Avg. Response Time', 'Avg. Resolution Time', 'Operational Efficiency', 'CSAT Score'],
        ['Top Unresolved Questions'],
        ['Questions', 'Justification', 'Category'],
        ['FAQ'],
        ['Questions', 'Answers', 'Time to Resolve without SuperDesk', 'Time to Resolve with SuperDesk'],
      ];
      targetRowDataList.forEach((targetRowData) => {
        // Iterate over each row in the worksheet
        worksheet.eachRow((row:any) => {
          // Check if the row data matches the current target row data
          let match = true;
          targetRowData.forEach((targetValue, index) => {
            if (row.getCell(index + 1).value !== targetValue) {
              match = false;
              return;
            }
          });

          if (match) {
            // Bold the cells in the matching row
            row.eachCell((cell:any) => {
              cell.font = { bold: true };
            });
          }
        });
      });
      // Generate a blob from the Excel workbook
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element and click it to initiate the download
      const a = document.createElement('a');
      a.href = url;
      a.download = 'HRSDHub.xlsx';
      a.click();

      // Release the URL object
      window.URL.revokeObjectURL(url);
    }
    catch (error) {
      // window.location.href = '/404';
    }
  };
  //PS_HR_1.62 - 68
  //To download the pdf
  const downloadPdf = async () => {
    // let input: any = pdfContent();
    let input: any = document.getElementById('pdf-content');
    let dashboard: any = document.getElementById('dashboard-content')
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;

    const options = {
      letterRendering: true
    };
    input.style.display = 'block';
    dashboard.style.display = 'none';

    (html2canvas as any)(input, options).then((canvas: any) => {
      const imageData = canvas.toDataURL('image/jpeg');
      const pdf = new jsPDF();
      let d = new Date();
      let n = d.toLocaleDateString();
      let imgWidth = 210;
      let pageHeight = 294;

      let imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;
      pdf.addImage(imageData, 'JPEG', 0, 0, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imageData, 'JPEG', 0, position, imgWidth, imgHeight + 15);
        heightLeft -= pageHeight;
      }
      pdf.save('HRSDHub.pdf');
    })
    input.style.display = 'none';
    dashboard.style.display = 'block';

  };
  const datePickerOnchange = (date: any, dateString: any) => {
    setFilterFromDate(dateString[0])
    setFilterToDate(dateString[1])

  }
  // UI to be rendered
  return (
    <div className="font-regular body-color">
      <Loader loading={!showLoader} />
      <div style={{ margin: 0, padding: 0, display: 'none' }} id='pdf-content'>
        <div style={{ fontFamily: 'sans-serif', lineHeight: '1.5', boxSizing: 'border-box', backgroundColor: '#F6F6F6', padding: '40px' }}>
          <h4 style={{ fontSize: '32px', fontWeight: 700, margin: '0.5rem 0 2rem 0' }}>HRSD</h4>
          {/* 3 column Section - Starts here */}
          <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
            <div style={{ width: '29.99%', padding: '1%', margin: '0 1% 0 0', borderRadius: '14px', backgroundColor: '#ffffff' }}>
              <h3 style={{ fontSize: '15px', fontWeight: 'bold', color: '#000000', margin: '0 0 0.5rem 0' }}>#Resolved Incidents</h3>
              <p style={{ fontSize: '28px', fontWeight: 600, margin: '0 0 1rem 0' }}>{incidents.noOfResolvedIncidents.incidentResolved != null ? incidents.noOfResolvedIncidents.incidentResolved : "0"}
                <span style={{ fontSize: '20px', fontWeight: 600 }}>out of {incidents.noOfResolvedIncidents.totalIncidents != null ? incidents.noOfResolvedIncidents.totalIncidents : "0"}</span>
              </p>
              <p style={{ fontSize: '14px', fontWeight: 400, marginBottom: 0, color: '#B0B0B0' }}>
                <span style={{ backgroundColor: '#EDFFEC', padding: '2px 8px', borderRadius: '10px', color: '#47C33C', fontWeight: 400, marginRight: '0.5rem' }}>{incidents.noOfResolvedIncidents.valueChange === "Increased" ? "+" : ""}{incidents.noOfResolvedIncidents.percentageVariation != null ? incidents.noOfResolvedIncidents.percentageVariation : "0"}%</span>{incidents.noOfResolvedIncidents.valueChange === "Increased" ? "increase" : "decrease"}
                than last {incidentReqObj.filterType ==="Last Year" || incidentReqObj.filterType ==="Current Year"?"year":"month"}
              </p>
            </div>
            <div style={{ width: '29.99%', padding: '1%', margin: '0 1%', borderRadius: '14px', backgroundColor: '#ffffff' }}>
              <h3 style={{ fontSize: '15px', fontWeight: 'bold', color: '#000000', margin: '0 0 0.5rem 0' }}>Avg. Response Time</h3>
              <p style={{ fontSize: '28px', fontWeight: 600, margin: '0 0 1rem 0' }}>{incidents.averageResponseTime.time ? (incidents.averageResponseTime.time.toString().split(':')[0] === "0.0 hr" && incidents.averageResponseTime.time.toString().split(':')[1] === " 0.0 mins" ? incidents.averageResponseTime.time.toString().split(':')[2] : incidents.averageResponseTime.time.toString().split(':')[0] === "0.0 hr" && incidents.averageResponseTime.time.toString().split(':')[1] !== " 0.0 mins" ? incidents.averageResponseTime.time.toString().split(':')[1] : incidents.averageResponseTime.time.toString().split(':')[0] !== "0.0 hr" ? incidents.averageResponseTime.time.toString().split(':')[0] : " 0.0 secs") : "0 secs"}
                <span style={{ fontSize: '20px', fontWeight: 600 }}></span>
              </p>
              <p style={{ fontSize: '14px', fontWeight: 400, margin: 0, color: '#B0B0B0' }}>
                <span style={{ backgroundColor: '#EDFFEC', padding: '2px 8px', fontWeight: 400, borderRadius: '10px', color: '#47C33C', margin: '0 0.5rem 0 0' }}>{incidents.averageResponseTime.valueChange === "Increased" ? "+" : ""}{incidents.averageResponseTime.percentageVariation != null ? incidents.averageResponseTime.percentageVariation : "0"}%</span>{incidents.averageResponseTime.valueChange === "Increased" ? "increase" : "decrease"} than last {incidentReqObj.filterType ==="Last Year" || incidentReqObj.filterType ==="Current Year"?"year":"month"}
              </p>
            </div>
            <div style={{ width: '29.99%', padding: '1%', margin: '0 0 0 1%', borderRadius: '14px', backgroundColor: '#ffffff' }}>
              <h3 style={{ fontSize: '15px', fontWeight: 'bold', color: '#000000', margin: '0 0 0.5rem 0' }}>Avg. Resolution Time</h3>
              <p style={{ fontSize: '28px', fontWeight: 600, margin: '0 0 1rem 0' }}>{incidents.averageResolutionTime.time ? (incidents.averageResolutionTime.time.toString().split(':')[0] === "0.0 hr" && incidents.averageResolutionTime.time.toString().split(':')[1] === " 0.0 mins" ? incidents.averageResolutionTime.time.toString().split(':')[2] : incidents.averageResolutionTime.time.toString().split(':')[0] === "0.0 hr" && incidents.averageResolutionTime.time.toString().split(':')[1] !== " 0.0 mins" ? incidents.averageResolutionTime.time.toString().split(':')[1] : incidents.averageResolutionTime.time.toString().split(':')[0] !== "0.0 hr" ? incidents.averageResolutionTime.time.toString().split(':')[0] : " 0.0 secs") : "0 secs"}
                <span style={{ fontSize: '20px', fontWeight: 600 }}></span>
              </p>
              <p style={{ fontSize: '14px', fontWeight: 400, marginBottom: 0, color: '#B0B0B0' }}>
                <span style={{ backgroundColor: '#EDFFEC', padding: '2px 8px', fontWeight: 400, borderRadius: '10px', color: '#47C33C', margin: '0 0.5rem 0 0' }}>-{incidents.averageResolutionTime.valueChange === "Increased" ? "+" : ""}{incidents.averageResolutionTime.percentageVariation != null ? incidents.averageResolutionTime.percentageVariation : "0"}%</span>{incidents.averageResolutionTime.valueChange === "Increased" ? "increase" : "decrease"} than last {incidentReqObj.filterType ==="Last Year" || incidentReqObj.filterType ==="Current Year"?"year":"month"}
              </p>
            </div>
          </div>
          {/* solved start here */}
          <div style={{ padding: '16px', backgroundColor: '#ffffff', borderRadius: '14px', marginTop: '1.5rem' }}>
            <h3 style={{ fontSize: '16px', color: 'black', margin: '5px' }}>Issues SuperDesk Solved</h3>
            <h5 style={{ fontSize: '16px', margin: '20px 0 0 5px', fontWeight: 400, color: 'black' }}>Questions</h5>
            {questions.issues.map((value: any, index: any) => {
              return (
                <div style={{ borderBottom: '1px solid #dee2e6' }}>
                  <h6 style={{ margin: '15px 10px 20px 10px', fontSize: '14px', fontWeight: 500 }}>{value.questions}
                  </h6>
                  <div style={{ boxShadow: '0px 1px 8px 2px rgba(0, 0, 0, 0.031372549)', borderRadius: '8px', padding: '1rem 1.25rem', marginBottom: '17px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img style={{ marginRight: '8px' }} src="./image/profile-logo-icon.svg" alt="profile-image" />
                      <span style={{ fontSize: '14px', fontWeight: 600, margin: '0px' }}>SuperDesk Response</span>
                    </div>
                    <div style={{ lineHeight: '24px', color: '#B0B0B0', marginBottom: 0 }}>
                      <p style={{ fontSize: '14px' }}>{value.answers}</p>
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                        <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#FAF1FF', padding: '8px', borderRadius: '20px', fontSize: '14px', color: '#212529', marginRight: '12px' }}>
                          <img style={{ margin: '0.25rem' }} src="./image/purple-clock.svg" alt="time-icon" />
                          <span style={{ fontSize: '12px' }}>Time to Resolve without SuperDesk:<span style={{ color: '#7C1AAA', marginLeft: '0.25rem', fontWeight: 'bold' }}>{value.timeWithoutSuperDesk}</span></span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#F0FFEE', padding: '8px', borderRadius: '20px', fontSize: '14px', color: '#212529' }}>
                          <img style={{ margin: '0.25rem' }} src="./image/green-clock.svg" alt="time-icon" />
                          <span style={{ fontSize: '12px' }}>Time to Resolve with SuperDesk:<span style={{ color: '#10B200', marginLeft: '0.25rem', fontWeight: 'bold' }}>{value.timeWithSuperDesk}</span></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <div style={{ padding: '16px', backgroundColor: '#ffffff', borderRadius: '14px', marginTop: '1.5rem' }}>
            <h3 style={{ fontSize: '16px', color: 'black', margin: '5px' }}>Requests SuperDesk Solved</h3>
            <h5 style={{ fontSize: '16px', margin: '20px 0 0 5px', fontWeight: 400, color: 'black' }}>Questions</h5>
            {questions.requests.map((value: any, index: any) => {
              return (
                <div style={{ borderBottom: '1px solid #dee2e6' }}>
                  <h6 style={{ margin: '15px 10px 20px 10px', fontSize: '14px', fontWeight: 500 }}> {value.questions}
                  </h6>
                  <div style={{ boxShadow: '0px 1px 8px 2px rgba(0, 0, 0, 0.031372549)', borderRadius: '8px', padding: '1rem 1.25rem', marginBottom: '17px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img style={{ marginRight: '8px' }} src="./image/profile-logo-icon.svg" alt="profile-image" />
                      <span style={{ fontSize: '14px', fontWeight: 600, margin: '0px' }}>SuperDesk Response</span>
                    </div>
                    <div style={{ lineHeight: '24px', color: '#B0B0B0', marginBottom: 0 }}>
                      <p style={{ fontSize: '14px' }}>{value.answers}</p>
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                        <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#FAF1FF', padding: '8px', borderRadius: '20px', fontSize: '14px', color: '#212529', marginRight: '12px' }}>
                          <img style={{ margin: '0.25rem' }} src="./image/purple-clock.svg" alt="time-icon" />
                          <span style={{ fontSize: '12px' }}>Time to Resolve without SuperDesk:<span style={{ color: '#7C1AAA', marginLeft: '0.25rem', fontWeight: 'bold' }}>{value.timeWithoutSuperDesk}</span></span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#F0FFEE', padding: '8px', borderRadius: '20px', fontSize: '14px', color: '#212529' }}>
                          <img style={{ margin: '0.25rem' }} src="./image/green-clock.svg" alt="time-icon" />
                          <span style={{ fontSize: '12px' }}>Time to Resolve with SuperDesk:<span style={{ color: '#10B200', marginLeft: '0.25rem', fontWeight: 'bold' }}>{value.timeWithSuperDesk}</span></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          {/* table start here */}
          <div style={{ padding: '16px', backgroundColor: '#ffffff', borderRadius: '14px', marginTop: '1.5rem' }}>
            <div style={{ minWidth: '955px', borderRadius: '16px' }}>
              <div style={{ borderRadius: '16px', border: 0, padding: '1rem' }}>
                <h1 style={{ fontSize: '20px', fontWeight: 600 }}>Top Unresolved Questions</h1>
                <div style={{ borderRadius: '14px', border: '1px solid #E6E6EA' }}>
                  <table style={{ verticalAlign: 'middle', borderCollapse: 'collapse', width: '100%', marginBottom: '1rem', color: '#212529' }}>
                    <thead style={{ borderColor: 'inherit', borderWidth: 0, backgroundColor: '#F8F2FF', fontSize: '14px' }}>
                      <tr>
                        <th style={{ paddingLeft: '1rem', textAlign: 'start', borderRadius: '14px 0px 0px 0px', padding: '18px 24px', fontWeight: 600 }}>Questions</th>
                        <th style={{ textAlign: 'start', fontWeight: 600 }}>Justification</th>
                        <th style={{ fontWeight: 600, paddingRight: '1rem', borderRadius: '0px 14px 0px 0px' }}>
                          Category</th>
                      </tr>
                    </thead>
                    <tbody style={{ fontSize: '14px', color: '#454545', fontWeight: 400 }}>
                      {cases.topUnresolvedCases.map((value: any, index: any) => {
                        return (
                          <tr style={{ backgroundColor: index % 2 === 0 ? '#F6F6F6' : 'transparent' }} >
                            <td style={{ paddingLeft: '1rem', padding: '24px' }}><a style={{ color: '#802DF5' }} href="#">{value.questions}</a></td>
                            <td>{value.justification}</td>
                            <td style={{ textAlign: 'center', paddingRight: '1rem' }}><span style={{ paddingRight: '1rem', paddingLeft: '1rem', paddingTop: '0.25rem', color: `${value.category==="Complex Scenario"?"#000":"#ffffff"}`, paddingBottom: '0.25rem', textAlign: 'center', fontSize: '13px', backgroundColor: `${value.category === "Complex Scenario" ? "#E0FF00" : value.category === "Vague Questions" ? "#1E2029" : value.category === "HR Policy Interpretations" ? "#9746FF" : value.category === "Advanced Technical" ? "#707070" : "#47C33C"}`, borderRadius: '70px', fontWeight: 700 }}>{value.category}</span></td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div style={{ padding: '16px', backgroundColor: '#ffffff', borderRadius: '14px', marginTop: '1.5rem' }}>
            <div style={{ minWidth: '955px',  borderRadius: '16px' }}>
              <div style={{ borderRadius: '16px', border: 0, padding: '1rem' }}>
                <h1 style={{ fontSize: '20px', fontWeight: 600 }}>Employee Satisfaction Insights</h1>
                <div style={{ borderRadius: '14px', border: '1px solid #E6E6EA' }}>
                  <table style={{ verticalAlign: 'middle', borderCollapse: 'collapse', width: '100%', marginBottom: '1rem', color: '#212529' }}>
                    <thead style={{ backgroundColor: '#F8F2FF', fontSize: '14px' }}>
                      <tr>
                        <th style={{ paddingLeft: '1rem', textAlign: 'start', borderRadius: '14px 0px 0px 0px', padding: '18px 24px', fontWeight: 600 }}>Questions</th>
                        <th style={{ fontWeight: 600, paddingRight: '1rem', borderRadius: '0px 14px 0px 0px' }}>
                          Feedback</th>
                      </tr>
                    </thead>
                    <tbody style={{ fontSize: '14px', color: '#454545', fontWeight: 400 }}>
                      {cases.employeeSatisfactionInsights.map((value: any, index: any) => {
                        return (
                          <tr style={{ backgroundColor: index % 2 === 0 ? '#F6F6F6' : 'transparent' }} >
                            <td style={{ paddingLeft: '1rem', padding: '24px' }}><a style={{ color: '#802DF5' }} href="#">{value.cases}</a></td>
                            <td style={{ textAlign: 'center', paddingRight: '1rem' }}><span style={{ paddingRight: '1rem', paddingLeft: '1rem', paddingTop: '0.25rem', color: `#000`, paddingBottom: '0.25rem', textAlign: 'center', fontSize: '13px', backgroundColor: `${value.Feedback === "Good" ? "#10B200" : value.Feedback === "Neutral" ? "#FF9900" : "#EC0000"}`, borderRadius: '70px', fontWeight: 700 }}>{value.Feedback}</span></td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* FAQ start here */}
          <div style={{ padding: '16px', borderRadius: '14px', backgroundColor: '#ffffff', fontFamily: 'sans-serif', lineHeight: '1.5px', marginBottom: '10px', marginTop: '1.5rem' }}>
            <h3 style={{ fontSize: '16px', color: 'black' }}>FAQ</h3>
            {questions.faq.map((value: any, index: any) => {
              return (
                <div style={{ borderBottom: '1px solid #dee2e6' }}>
                  <h6 style={{ marginTop: '30px', fontSize: '14px', fontWeight: 500 }}> {value.questions}</h6>
                  <div style={{ boxShadow: '0px 1px 8px 2px rgba(0, 0, 0, 0.031372549)', borderRadius: '8px', padding: '1rem 1.25rem', marginBottom: '17px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img style={{ marginRight: '8px' }} src="./image/profile-logo-icon.svg" alt="profile-image" />
                      <span style={{ fontSize: '14px', fontWeight: 600, margin: '0px' }}>SuperDesk Response</span>
                    </div>
                    <div style={{ lineHeight: '24px', color: '#B0B0B0', marginBottom: 0 }}>
                      <p style={{ fontSize: '14px' }}>{value.answers}</p>
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                        <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#FAF1FF', padding: '8px', borderRadius: '20px', fontSize: '14px', color: '#212529', marginRight: '12px' }}>
                          <img style={{ margin: '0.25rem' }} src="./image/purple-clock.svg" alt="time-icon" />
                          <span style={{ fontSize: '12px' }}>Time to Resolve without SuperDesk:<span style={{ color: '#7C1AAA', marginLeft: '0.25rem', fontWeight: 'bold' }}>{value.timeWithoutSuperDesk}</span></span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#F0FFEE', padding: '8px', borderRadius: '20px', fontSize: '14px', color: '#212529' }}>
                          <img style={{ margin: '0.25rem' }} src="./image/green-clock.svg" alt="time-icon" />
                          <span style={{ fontSize: '12px' }}>Time to Resolve with SuperDesk:<span style={{ color: '#10B200', marginLeft: '0.25rem', fontWeight: 'bold' }}>{value.timeWithSuperDesk}</span></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="d-flex" id='dashboard-content' style={{ display: 'block' }}>
        <LeftsidePanel />
        <div className="content-container">
          {/* Page Title */}
          <div className="d-md-flex justify-content-between mb-4">
            <h1 className="font-32 shadow-black font-bold">HRSD</h1>
            <div className="d-flex justify-content-end">
              <div className="dropdown me-2" hidden={incidentReqObj.filterType === "Custom Date Range"}>
                <button className="btn btn-secondary dropdown-toggle sd-select" type="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => {
                  incidentReqObj.filterType === "Custom Date Range" ? setShowFilter(true) : setShowFilter(false)
                }}>
                  {incidentReqObj.filterType}
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li className="dropdown-item" onClick={() => { setIncidentReqObj({ ...incidentReqObj, filterType: "Current Year" }); setShowFilter(false) }}>Current Year</li>
                  <li className="dropdown-item" onClick={() => { setIncidentReqObj({ ...incidentReqObj, filterType: "Last Year" }); setShowFilter(false) }}>Last Year</li>
                  <li className="dropdown-item" onClick={() => { setIncidentReqObj({ ...incidentReqObj, filterType: "Current Month" }); setShowFilter(false) }}>Current Month</li>
                  <li className="dropdown-item" onClick={() => { setIncidentReqObj({ ...incidentReqObj, filterType: "Last Month" }); setShowFilter(false) }}>Last Month</li>
                  <li className="dropdown-item" onClick={() => { setIncidentReqObj({ ...incidentReqObj, filterType: "Last 6 Months" }); setShowFilter(false) }}>Last 6 Months</li>
                  <li className="dropdown-item" onClick={() => { setIncidentReqObj({ ...incidentReqObj, filterType: "Custom Date Range" }); setShowFilter(true) }}>Custom Date Range</li>
                </ul>
              </div>

              <div className="dropdown me-2" hidden={incidentReqObj.filterType !== "Custom Date Range"}>
                <button className="btn btn-secondary dropdown-toggle sd-select" type="button" onClick={() => {
                  incidentReqObj.filterType === "Custom Date Range" ? setShowFilter(true) : setShowFilter(false)
                }}>
                  {incidentReqObj.filterType}
                </button>
                {showFilter && <ul className={`dropdown-menu sd-custom-timeline px-0 pt-3 filter-width d-block align-custom-filter`} aria-labelledby="dropdownMenuButton1">
                  <li className="h-100">
                    <div className="d-flex align-items-start container-width">
                      <div className="nav flex-column nav-pills me-3 font-12" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <button className="nav-link" id="sd-Current-Year-tab" data-bs-toggle="pill" data-bs-target="#sd-Current-Year" type="button" role="tab" aria-controls="sd-Current-Year" aria-selected="false" onClick={() => { setIncidentReqObj({ ...incidentReqObj, filterType: "Current Year" }); setShowFilter(false) }}>Current Year</button>
                        <button className="nav-link" id="sd-Current-Year-tab" data-bs-toggle="pill" data-bs-target="#sd-Current-Year" type="button" role="tab" aria-controls="sd-Current-Year" aria-selected="false" onClick={() => { setIncidentReqObj({ ...incidentReqObj, filterType: "Last Year" }); setShowFilter(false) }}>Last Year</button>
                        <button className="nav-link" id="sd-Last-Year-tab" data-bs-toggle="pill" data-bs-target="#sd-Last-Year" type="button" role="tab" aria-controls="sd-Last-Year" aria-selected="false" onClick={() => { setIncidentReqObj({ ...incidentReqObj, filterType: "Current Month" }); setShowFilter(false) }}>Current Month</button>
                        <button className="nav-link" id="sd-Last-Month-tab" data-bs-toggle="pill" data-bs-target="#sd-Last-Month" type="button" role="tab" aria-controls="sd-Last-Month" aria-selected="false" onClick={() => { setIncidentReqObj({ ...incidentReqObj, filterType: "Last Month" }); setShowFilter(false) }}>Last Month</button>
                        <button className="nav-link" id="sd-Last-6-Months-tab" data-bs-toggle="pill" data-bs-target="#sd-Last-6-Months" type="button" role="tab" aria-controls="sd-Last-6-Months" aria-selected="false" onClick={() => { setIncidentReqObj({ ...incidentReqObj, filterType: "Last 6 Months" }); setShowFilter(false) }}>Last 6 Months</button>
                        <button className="nav-link active" id="custom-datarange-tab" data-bs-toggle="pill" data-bs-target="#custom-datarange" type="button" role="tab" aria-controls="custom-datarange" aria-selected="true" onClick={() => { setIncidentReqObj({ ...incidentReqObj, filterType: "Custom Date Range" }); setShowFilter(true) }}>Custom Date Range</button>
                      </div>
                      <div className="tab-content" id="v-pills-tabContent">
                        <div className="tab-pane fade show active" id="custom-datarange" role="tabpanel">
                          <ConfigProvider
                            theme={{
                              token: {
                                // Seed Token
                                colorPrimary: '#753ced',
                                borderRadius: 2,

                                // Alias Token
                                colorBgContainer: '#f6ffed',
                              },
                            }}
                          >
                            <Space direction="vertical" size={12}>
                              <RangePicker
                                open={incidentReqObj.filterType === "Custom Date Range" && showFilter}
                                onChange={datePickerOnchange}
                                dropdownClassName='set-picker-inset'
                                format={'MM/DD/YYYY'}
                              />
                            </Space>
                          </ConfigProvider>
                        </div>
                      </div>
                    </div>
                    <div className="text-end p-2 border-top">
                      {/* <label className="font-12 color-black font-medium me-2">03/06/2024 - 04/04/2024</label> */}
                      <button type="button" className="btn secondary-btn font-12 font-semibold me-2" onClick={() => { setShowFilter(false) }}>Reset</button>
                      <button type="button" className="btn primary-btn font-12 font-semibold" onClick={() => { setIncidentReqObj({ ...incidentReqObj, fromDate: filterFromDate, toDate: filterToDate }); setShowFilter(false) }}>Apply</button>
                    </div>
                  </li>
                </ul>}
              </div>
              <span className="sd-action-icon me-2" onClick={() => downloadPdf()}>
                <img src="image/pdf-icon.svg" alt="pdf-icon" width="24px" />
              </span>
              <span className="sd-action-icon" onClick={() => downloadExcel()}>
                <img src="image/xlx-icon.svg" alt="xlx-icon" width="24px" />
              </span>
            </div>
          </div>
          {/* 2nd Section - Starts here */}
          <div className="mb-4">
            <div className="py-4 collapse show" id="collapseLeads">
              <div className="row">
                <div className="col-md-4 mb-4">
                  <div className="sd-card">
                    <h3 className="font-16 font-medium color-black">#Resolved Incidents</h3>
                    <p className="font-28 font-semibold mb-3">{incidents.noOfResolvedIncidents.totalIncidents != null ? incidents.noOfResolvedIncidents.totalIncidents : "0"} <span className="font-20 font-medium">out of {incidents.noOfResolvedIncidents.totalIncidents != null ? incidents.noOfResolvedIncidents.totalIncidents : "0"}</span></p>
                    <p className={incidents.noOfResolvedIncidents.valueChange === "Increased"?"font-14 mb-0 color-green":"color-red font-14 mb-0"}><span className={incidents.noOfResolvedIncidents.valueChange === "Increased"?"green-badge me-2":"decrease me-2"}>{incidents.noOfResolvedIncidents.valueChange === "Increased" ? "+" : ""}{incidents.noOfResolvedIncidents.percentageVariation != null ? incidents.noOfResolvedIncidents.percentageVariation : "0"}%</span>{incidents.noOfResolvedIncidents.valueChange === "Increased" ? "increase" : "decrease"} than last {incidentReqObj.filterType ==="Last Year" || incidentReqObj.filterType ==="Current Year"?"year":"month"}</p>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="sd-card">
                    <h3 className="font-16 font-medium color-black">Avg. Response Time</h3>
                    <p className="font-28 font-semibold mb-3">{incidents.averageResponseTime.time ? (incidents.averageResponseTime.time.toString().split(':')[0] === "0.0 hr" && incidents.averageResponseTime.time.toString().split(':')[1] === " 0.0 mins" ? incidents.averageResponseTime.time.toString().split(':')[2] : incidents.averageResponseTime.time.toString().split(':')[0] === "0.0 hr" && incidents.averageResponseTime.time.toString().split(':')[1] !== " 0.0 mins" ? incidents.averageResponseTime.time.toString().split(':')[1] : incidents.averageResponseTime.time.toString().split(':')[0] !== "0.0 hr" ? incidents.averageResponseTime.time.toString().split(':')[0] : " 0.0 secs") : "0"} <span className="font-20 font-medium"></span>
                    </p>
                    <p className={incidents.averageResponseTime.valueChange === "Increased" ?"font-14 mb-0 color-green":"color-red font-14 mb-0"}><span className={incidents.averageResponseTime.valueChange === "Increased" ?"green-badge me-2":"decrease me-2"}>{incidents.averageResponseTime.valueChange === "Increased" ? "+" : ""}{incidents.averageResponseTime.percentageVariation ? incidents.averageResponseTime.percentageVariation : "0"}%</span>{incidents.averageResponseTime.valueChange === "Increased" ? "increase" : "decrease"} than last {incidentReqObj.filterType ==="Last Year" || incidentReqObj.filterType ==="Current Year"?"year":"month"}</p>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="sd-card">
                    <h3 className="font-16 font-medium color-black">Avg. Resolution Time</h3>
                    <p className="font-28 font-semibold mb-3">{incidents.averageResolutionTime.time ? (incidents.averageResolutionTime.time.toString().split(':')[0] === "0.0 hr" && incidents.averageResolutionTime.time.toString().split(':')[1] === " 0.0 mins" ? incidents.averageResolutionTime.time.toString().split(':')[2] : incidents.averageResolutionTime.time.toString().split(':')[0] === "0.0 hr" && incidents.averageResolutionTime.time.toString().split(':')[1] !== " 0.0 mins" ? incidents.averageResolutionTime.time.toString().split(':')[1] : incidents.averageResolutionTime.time.toString().split(':')[0] !== "0.0 hr" ? incidents.averageResolutionTime.time.toString().split(':')[0] : " 0.0 secs") : "0"} <span className="font-20 font-medium"></span>
                    </p>
                    <p className={incidents.averageResolutionTime.valueChange === "Increased" ?"font-14 mb-0 color-green":"color-red font-14 mb-0"}><span className={incidents.averageResolutionTime.valueChange === "Increased" ?"green-badge me-2":"decrease me-2"}>{incidents.averageResolutionTime.valueChange === "Increased" ? "+" : ""}{incidents.averageResolutionTime.percentageVariation ? incidents.averageResolutionTime.percentageVariation : "0"}%</span>{incidents.averageResolutionTime.valueChange === "Increased" ? "increase" : "decrease"} than last {incidentReqObj.filterType ==="Last Year" || incidentReqObj.filterType ==="Current Year"?"year":"month"}</p>
                  </div>
                </div>
                <div className="col-md-12 col-lg-12 mb-4">
                  <div className="sd-card h-100">
                    {/* Widget Title */}
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <div className="d-flex align-items-center gap-4">
                        <h3 className="font-16 font-medium color-black mb-0">Issues SuperDesk Solved</h3>
                        {/* Tab Button Structure here */}
                        <div className="text-start">
                          <div className="dropdown me-2 d-block d-md-none">
                            <button className="btn btn-secondary dropdown-toggle sd-select" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                              Solved
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                              <li className="dropdown-item" onClick={() => { setQuestionsReqObj({ ...questionsReqObj, issueType: "Solved" }) }}>Solved</li>
                              <li className="dropdown-item" onClick={() => { setQuestionsReqObj({ ...questionsReqObj, issueType: "Assisted" }) }}>Assist On</li>
                            </ul>
                          </div>
                          <ul className="nav nav-pills tab-pill d-md-flex d-none" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                              <button className="nav-link tab-title active font-14" data-bs-toggle="pill" data-bs-target="#issue_solved" type="button" role="tab" aria-selected="true" onClick={() => { setQuestionsReqObj({ ...questionsReqObj, issueType: "Solved" }) }}>Solved</button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button className="nav-link tab-title font-14" data-bs-toggle="pill" data-bs-target="#issue_assist" type="button" role="tab" aria-selected="false" onClick={() => { setQuestionsReqObj({ ...questionsReqObj, issueType: "Assisted" }) }}>Assist On</button>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="sd-explain-icon justify-content-end"><img src="image/expand-icon.svg" alt="expand-icon" onClick={() => setshowissuesPopup(true)} /></div>
                    </div>
                    {/* Tab Structure here */}
                    <div className="tab-content" id="pills-tabContent">
                      {/* Solved here */}
                      <div className="tab-pane fade show active" id="issue_solved" role="tabpanel">
                        <p>Questions</p>
                        <div className="accordion" id="accordionExample-issuessolved">
                          {questions.issues.map((value: any, index: any) => {
                            if(index<5)
                            return (
                              <div className={`accordion-item border-bottom border-0 ${(index === 0 || index === questions.issues.length - 1) ? "rounded=0" : ""}`}>
                                <h2 className="accordion-header my-3" id={`issuessolved-heading${index}`}>
                                  <button className="custom-accordion-btn collapsed w-100 d-flex font-14 grey-v3 text-start align-items-center p-0" type="button" data-bs-toggle="collapse" data-bs-target={`#issuessolved-collapse${index}`} aria-expanded="false" aria-controls={`issuessolved-collapse${index}`}>
                                    {value.questions}
                                  </button>
                                </h2>
                                <div id={`issuessolved-collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`issuessolved-heading${index}`} data-bs-parent="#accordionExample-issuessolved">
                                  <div className="accordion-body custom-box-shadow custom-left-border mb-3 custom-scroll">
                                    <div className='custom-line'>
                                    <div className="d-flex align-items-center">
                                      <img className="me-2" src="./image/profile-logo-icon.svg" alt="profile-image" />
                                      <span className="font-14 font-semibold">SuperDesk Response</span>
                                    </div>
                                    <p className="mb-0 font-14 color-greyv1 mt-2 custom-lineheight">{value.answers}</p>
                                    <div className="d-md-flex align-items-center mt-3 d-block gap-2">
                                      <div className=" d-flex align-items-center time-with font-12 mb-3 mb-md-0">
                                        <img className="m-1" src="./image/purple-clock.svg" alt="clock-icon" />
                                        <span>Time to Resolve without SuperDesk:<span className="ms-1 font-bold purplev1">{value.timeWithoutSuperDesk}</span></span>
                                      </div>
                                      <div className="d-flex align-items-center time-without font-12">
                                        <img className="m-1" src="./image/green-clock.svg" alt="clock-icon" />
                                        <span>Time to Resolve with SuperDesk:<span className="ms-1 font-bold greenv1">{value.timeWithSuperDesk}</span></span>
                                      </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      {/* Assist On here */}
                      <div className="tab-pane fade" id="issue_assist" role="tabpanel">
                        <p>Questions</p>
                        <div className="accordion" id="accordionExample-issuesassisted">
                          {questions.issues.map((value: any, index: any) => {
                            if(index<5)
                            return (
                              <div className={`accordion-item border-bottom border-0 ${(index === 0 || index === questions.issues.length - 1) ? "rounded=0" : ""}`}>
                                <h2 className="accordion-header my-3" id={`issuesassisted-heading${index}`}>
                                  <button className="custom-accordion-btn collapsed w-100 d-flex font-14 grey-v3 text-start align-items-center p-0" type="button" data-bs-toggle="collapse" data-bs-target={`#issuesassisted-collapse${index}`} aria-expanded="false" aria-controls={`issuessolved-collapse${index}`}>
                                    {value.questions}
                                  </button>
                                </h2>
                                <div id={`issuesassisted-collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`issuesassisted-heading${index}`} data-bs-parent="#accordionExample-issuesassisted">
                                  <div className="accordion-body custom-box-shadow custom-left-border mb-3 custom-scroll">
                                  <div className='custom-line'>
                                    <div className="d-flex align-items-center">
                                      <img className="me-2" src="./image/profile-logo-icon.svg" alt="profile-image" />
                                      <span className="font-14 font-semibold">SuperDesk Response</span>
                                    </div>
                                    <p className="mb-0 font-14 color-greyv1 mt-2 custom-lineheight">{value.answers}</p>
                                    <div className="d-md-flex align-items-center mt-3 d-block gap-2">
                                      <div className=" d-flex align-items-center time-with font-12 mb-3 mb-md-0">
                                        <img className="m-1" src="./image/purple-clock.svg" alt="clock-icon" />
                                        <span>Time to Resolve without SuperDesk:<span className="ms-1 font-bold purplev1">{value.timeWithoutSuperDesk}</span></span>
                                      </div>
                                      <div className="d-flex align-items-center time-without font-12">
                                        <img className="m-1" src="./image/green-clock.svg" alt="clock-icon" />
                                        <span>Time to Resolve with SuperDesk:<span className="ms-1 font-bold greenv1">{value.timeWithSuperDesk}</span></span>
                                      </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-12 mb-4">
                  <div className="sd-card h-100">
                    {/* Widget Title */}
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <div className="d-flex align-items-center gap-4">
                        <h3 className="font-16 font-medium color-black mb-0">Requests SuperDesk Solved</h3>
                        {/* Tab Button Structure here */}
                        <div className="text-start">
                          <div className="dropdown me-2 d-block d-md-none">
                            <button className="btn btn-secondary dropdown-toggle sd-select" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                              Solved
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                              <li className="dropdown-item" onClick={() => { setQuestionsReqObj({ ...questionsReqObj, requestsType: "Solved" }) }}>Solved</li>
                              <li className="dropdown-item" onClick={() => { setQuestionsReqObj({ ...questionsReqObj, requestsType: "Assited" }) }}>Assist On</li>
                            </ul>
                          </div>
                          <ul className="nav nav-pills tab-pill d-md-flex d-none" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                              <button className="nav-link tab-title active font-14" data-bs-toggle="pill" data-bs-target="#request_solved" type="button" role="tab" aria-selected="true" onClick={() => { setQuestionsReqObj({ ...questionsReqObj, requestsType: "Solved" }) }}>Solved</button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button className="nav-link tab-title font-14" data-bs-toggle="pill" data-bs-target="#request_assist" type="button" role="tab" aria-selected="false" onClick={() => { setQuestionsReqObj({ ...questionsReqObj, requestsType: "Assited" }) }}>Assist On</button>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="sd-explain-icon justify-content-end"><img src="image/expand-icon.svg" alt="expand-icon" onClick={() => setshowrequestsPopup(true)} /></div>
                    </div>
                    {/* Tab Structure here */}
                    <div className="tab-content" id="pills-tabContent">
                      {/* Solved here */}
                      <div className="tab-pane fade show active" id="request_solved" role="tabpanel">
                        <p>Questions</p>
                        <div className="accordion" id="accordionExample-requestsolved">
                          {questions.requests.map((value: any, index: any) => {
                            if(index<5)
                            return (
                              <div className={`accordion-item border-bottom border-0 ${(index === 0 || index === questions.requests.length - 1) ? "rounded=0" : ""}`}>
                                <h2 className="accordion-header my-3" id={`requestsolved-heading${index}`}>
                                  <button className="custom-accordion-btn collapsed w-100 d-flex font-14 grey-v3 text-start align-items-center p-0" type="button" data-bs-toggle="collapse" data-bs-target={`#requestsolved-collapse${index}`} aria-expanded="false" aria-controls={`requestsolved-collapse${index}`}>
                                    {value.questions}
                                  </button>
                                </h2>
                                <div id={`requestsolved-collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`requestsolved-heading${index}`} data-bs-parent="#accordionExample-requestsolved">
                                  <div className="accordion-body custom-box-shadow custom-left-border mb-3 custom-scroll">
                                    <div className='custom-line'>
                                    <div className="d-flex align-items-center">
                                      <img className="me-2" src="./image/profile-logo-icon.svg" alt="profile-image" />
                                      <span className="font-14 font-semibold">SuperDesk Response</span>
                                    </div>
                                    <p className="mb-0 font-14 color-greyv1 mt-2 custom-lineheight">{value.answers}</p>
                                    <div className="d-md-flex align-items-center mt-3 d-block gap-2">
                                      <div className=" d-flex align-items-center time-with font-12 mb-3 mb-md-0">
                                        <img className="m-1" src="./image/purple-clock.svg" alt="clock-icon" />
                                        <span>Time to Resolve without SuperDesk:<span className="ms-1 font-bold purplev1">{value.timeWithoutSuperDesk}</span></span>
                                      </div>
                                      <div className="d-flex align-items-center time-without font-12">
                                        <img className="m-1" src="./image/green-clock.svg" alt="clock-icon" />
                                        <span>Time to Resolve with SuperDesk:<span className="ms-1 font-bold greenv1">{value.timeWithSuperDesk}</span></span>
                                      </div>
                                    </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      {/* Assist On here */}
                      <div className="tab-pane fade" id="request_assist" role="tabpanel">
                        <p>Questions</p>
                        <div className="accordion" id="accordionExample-requestassisted">
                          {questions.requests.map((value: any, index: any) => {
                            if(index<5)
                            return (
                              <div className={`accordion-item border-bottom border-0 ${(index === 0 || index === questions.requests.length - 1) ? "rounded=0" : ""}`}>
                                <h2 className="accordion-header my-3" id={`requestassisted-heading${index}`}>
                                  <button className="custom-accordion-btn collapsed w-100 d-flex font-14 grey-v3 text-start align-items-center p-0" type="button" data-bs-toggle="collapse" data-bs-target={`#requestassisted-collapse${index}`} aria-expanded="false" aria-controls={`requestassisted-collapse${index}`}>
                                    {value.questions}
                                  </button>
                                </h2>
                                <div id={`requestassisted-collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`requestassisted-heading${index}`} data-bs-parent="#accordionExample-requestassisted">
                                  <div className="accordion-body custom-box-shadow custom-left-border mb-3">
                                    <div className='custom-line'>
                                    <div className="d-flex align-items-center">
                                      <img className="me-2" src="./image/profile-logo-icon.svg" alt="profile-image" />
                                      <span className="font-14 font-semibold">SuperDesk Response</span>
                                    </div>
                                    <p className="mb-0 font-14 color-greyv1 mt-2 custom-lineheight">{value.answers}</p>
                                    <div className="d-md-flex align-items-center mt-3 d-block gap-2">
                                      <div className=" d-flex align-items-center time-with font-12 mb-3 mb-md-0">
                                        <img className="m-1" src="./image/purple-clock.svg" alt="clock-icon" />
                                        <span>Time to Resolve without SuperDesk:<span className="ms-1 font-bold purplev1">{value.timeWithoutSuperDesk}</span></span>
                                      </div>
                                      <div className="d-flex align-items-center time-without font-12">
                                        <img className="m-1" src="./image/green-clock.svg" alt="clock-icon" />
                                        <span>Time to Resolve with SuperDesk:<span className="ms-1 font-bold greenv1">{value.timeWithSuperDesk}</span></span>
                                      </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-6 col-xl-6 mb-4">
                  <div className="sd-card h-100">
                    <div className="d-flex justify-content-between">
                      <h3 className="font-16 font-medium color-black">Unresolved SuperDesk Cases</h3>
                      <span className="sd-explain-icon"><img src="image/expand-icon.svg" alt="expand-icon" onClick={() => setshowTopUnresolvedCasesPopup(true)} /></span>
                    </div>
                    <ul className="sd-list list-unstyled mb-0">
                      <li className="d-flex align-items-center">
                        <div className="sd-w-80">
                          <p className="color-greyv1 font-14 font-regular mb-0">Cases</p>
                        </div>
                        <div className="sd-w-20">
                          <p className="color-greyv1 font-14 font-regular mb-0 text-center">Action</p>
                        </div>
                      </li>
                      {cases.topUnresolvedCases.map((value: any, index: any) => {
                        if (index < 5)
                          return (
                            <li className={`d-flex align-items-center  ${index != 0 ? "border-top" : "pt-0"}`}>
                              <div className="sd-w-80">
                                <p className="color-black font-14 font-regular mb-0">{value.questions}</p>
                              </div>
                              <div className="sd-w-20 text-center">
                                <img src="image/view-icon.svg" alt="view-icon" onClick={() => { setshowViewPopup(true); setviewCategory(value.category); setviewJustification(value.justification); setviewTitle("Unresolved SuperDesk Cases"); setviewQuestion(value.questions) }} />
                              </div>
                            </li>
                          )
                      })}
                    </ul>
                  </div>
                </div>
                <div className="col-md-12 col-lg-6 col-xl-6 mb-4">
                  <div className="sd-card h-100">
                    <div className="d-flex justify-content-between">
                      <h3 className="font-16 font-medium color-black">Employee Satisfaction Insights</h3>
                      <span className="sd-explain-icon"><img src="image/expand-icon.svg" alt="expand-icon" onClick={() => setshowemployeePopup(true)} /></span>
                    </div>
                    <ul className="sd-list list-unstyled mb-0">
                      <li className="d-flex align-items-center">
                        <div className="sd-w-80">
                          <p className="color-greyv1 font-14 font-regular mb-0">Cases</p>
                        </div>
                        <div className="sd-w-20">
                          <p className="color-greyv1 font-14 font-regular mb-0 text-left">Feedback</p>
                        </div>
                      </li>
                      {cases.employeeSatisfactionInsights.map((value: any, index: any) => {
                        if (index < 5)
                          return (
                            <li className={`d-flex align-items-center ${index != 0 ? "border-top" : "pt-0"} gap-1`}>
                              <div className="sd-w-80">
                                <p className="color-black font-14 font-regular mb-0">{value.cases}</p>
                              </div>
                              <div className="sd-w-20 text-left">
                                <p className={`${value.Feedback === "Good" ? "greenv1" : value.Feedback === "Neutral" ? "color-orange" : "color-red"} font-14 font-medium mb-0`}>{value.Feedback}</p>
                              </div>
                            </li>
                          )
                      })}
                    </ul>
                  </div>
                </div>
                <div className="col-md-12 col-lg-12">
                  <div className="sd-card">
                    <div className="d-flex justify-content-between">
                      <h3 className="font-16 font-medium color-black">FAQ</h3>
                      <span className="sd-explain-icon"><img src="image/expand-icon.svg" alt="expand-icon" onClick={() => setshowfaqPopup(true)} /></span>
                    </div>
                    <div className="accordion" id="accordionExampleFAQ">
                      {questions.faq.map((value: any, index: any) => {
                        if (index < 5)
                          return (
                            <div className={`accordion-item border-bottom border-0 ${(index === 0 || index === questions.issues.length - 1) ? "rounded=0" : ""}`}>
                              <h2 className="accordion-header my-3" id={`faq-heading${index}`}>
                                <button className="custom-accordion-btn collapsed w-100 d-flex font-14 grey-v3 text-start align-items-center p-0" type="button" data-bs-toggle="collapse" data-bs-target={`#faq-collapse${index}`} aria-expanded="false" aria-controls={`faq-collapse${index}`}>
                                  {value.questions}
                                </button>
                              </h2>
                              <div id={`faq-collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`faq-heading${index}`} data-bs-parent="#accordionExampleFAQ">
                                <div className="accordion-body custom-box-shadow custom-left-border mb-3 custom-scroll">
                                  <div className='custom-line'>
                                  <div className="d-flex align-items-center">
                                    <img className="me-2" src="./image/profile-logo-icon.svg" alt="profile-image" />
                                    <span className="font-14 font-semibold">SuperDesk Response</span>
                                  </div>
                                  <p className="mb-0 font-14 color-greyv1 mt-2 custom-lineheight">{value.answers}</p>
                                  <div className="d-md-flex align-items-center mt-3 d-block gap-2">
                                    <div className=" d-flex align-items-center time-with font-12 mb-3 mb-md-0">
                                      <img className="m-1" src="./image/purple-clock.svg" alt="clock-icon" />
                                      <span>Time to Resolve without SuperDesk:<span className="ms-1 font-bold purplev1">{value.timeWithoutSuperDesk}</span></span>
                                    </div>
                                    <div className="d-flex align-items-center time-without font-12">
                                      <img className="m-1" src="./image/green-clock.svg" alt="clock-icon" />
                                      <span>Time to Resolve with SuperDesk:<span className="ms-1 font-bold greenv1">{value.timeWithSuperDesk}</span></span>
                                    </div>
                                  </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showTopUnresolvedCasesPopup &&
            <div className="body-bg model open">
              <div className="modal fade show d-block" id="exampleModalcases" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="false" >
                <div className="modal-dialog modal-dialog-centered service-container">
                  <div className="modal-content custom-border p-3 border-0">
                    <div className="sd-card h-100">
                      <div className="d-flex justify-content-between align-items-center mt-1 mb-4">
                        <h3 className="font-16 font-medium color-black">Unresolved SuperDesk Cases</h3>
                        <div className="d-flex justify-content-end">
                        <div className="dropdown d-md-flex me-2">
                          <button className="btn btn-secondary dropdown-toggle sd-select font-14 font-regular" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            {filterCategory}
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li className="dropdown-item" onClick={() => { setfilterCategory("All Categories") }}>All Categories</li>
                            <li className="dropdown-item" onClick={() => { setfilterCategory("Complex Scenario") }}>Complex Scenario</li>
                            <li className="dropdown-item" onClick={() => { setfilterCategory("Vague Questions") }}>Vague Questions</li>
                            <li className="dropdown-item" onClick={() => { setfilterCategory("HR Policy Interpretations") }}>HR Policy Interpretations</li>
                            <li className="dropdown-item" onClick={() => { setfilterCategory("Subjective Questions") }}>Subjective Questions</li>
                            <li className="dropdown-item" onClick={() => { setfilterCategory("Advanced Technical") }}>Advanced Technical</li>
                          </ul>
                        </div>
                        <button type="button" className="btn d-flex align-items-center custom-close p-2 me-2" data-bs-dismiss="modal" aria-label="Close"><img src="image/close-icon.svg" alt="Close" className="dark-theme-icon" onClick={() => { setshowTopUnresolvedCasesPopup(false); setfilterCategory("All Categories") }} /></button>
                      </div>
                        {/* <button type="button" className="btn d-flex align-items-center custom-close p-2 me-2" data-bs-dismiss="modal" aria-label="Close"><img src="image/close-icon.svg" alt="Close" className="dark-theme-icon" onClick={() => setshowTopUnresolvedCasesPopup(false)} /></button> */}
                      </div>
                      <div className="modal-body popup-scroll p-0 px-3 table-responsive">
                        <table className="table table-borderless theme-table align-middle">
                          <thead className="table-heading">
                            <tr>
                              <th className="ps-3 align-middle">Questions</th>
                              <th className="align-middle">Justification</th>
                              <th className="align-middle text-center">Category</th>
                            </tr>
                          </thead>
                          <tbody>
                            {topUnresolvedQuestionsPopupData.map((value: any, index: any) => {
                              return (
                                <tr>
                                  <td className="ps-3"><a className="id" href="#">{value.questions}</a></td>
                                  <td>{value.justification}</td>
                                  <td className="text-center"><span className={`custom-btn-v${value.category==="Complex Scenario"?"1":value.category==="Vague Questions"?"2":value.category==="HR Policy Interpretations"?"3":value.category==="Advanced Technical"?"4":"5"} text-nowrap text-center px-3 py-1 font-semibold font-13`}>{value.category}</span></td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-backdrop fade show"></div>
            </div>}
          {showemployeePopup &&
            <div className="body-bg model open">
              <div className="modal fade show d-block" id="exampleModalemployee" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="false" >
                <div className="modal-dialog modal-dialog-centered service-container">
                  <div className="modal-content custom-border p-3 border-0">
                    <div className="sd-card h-100">
                      <div className="d-flex justify-content-between">
                        <h3 className="font-16 font-medium color-black">Employee Satisfaction Insights</h3>
                        <button type="button" className="btn d-flex align-items-center custom-close p-2 me-2" data-bs-dismiss="modal" aria-label="Close"><img src="image/close-icon.svg" alt="Close" className="dark-theme-icon" onClick={() => setshowemployeePopup(false)} /></button>
                      </div>
                      <ul className="sd-list list-unstyled mb-0 popup-scroll">
                        <li className="d-flex align-items-center">
                          <div className="sd-w-80">
                            <p className="color-greyv1 font-14 font-regular mb-0">Cases</p>
                          </div>
                          <div className="sd-w-20">
                            <p className="color-greyv1 font-14 font-regular mb-0 text-left">Feedback</p>
                          </div>
                        </li>
                        {cases.employeeSatisfactionInsights.map((value: any, index: any) => {
                          return (
                            <li className={`d-flex align-items-center ${index != 0 ? "border-top" : "pt-0"} gap-1`}>
                              <div className="sd-w-80">
                                <p className="color-black font-14 font-regular mb-0">{value.cases}</p>
                              </div>
                              <div className="sd-w-20 text-left">
                                <p className={`${value.Feedback === "Good" ? "greenv1" : value.Feedback === "Neutral" ? "color-orange" : "color-red"} font-14 font-medium mb-0`}>{value.Feedback}</p>
                              </div>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-backdrop fade show"></div>
            </div>}
          {showfaqPopup &&
            <div className="body-bg model open">
              <div className="modal fade show d-block" id="exampleModalfaq" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="false" >
                <div className="modal-dialog modal-dialog-centered service-container">
                  <div className="modal-content custom-border p-3 border-0">
                    <div className="col-md-12 col-lg-12">
                      <div className="sd-card">
                        <div className="d-flex justify-content-between">
                          <h3 className="font-16 font-medium color-black">FAQ</h3>
                          <button type="button" className="btn d-flex align-items-center custom-close p-2 me-2" data-bs-dismiss="modal" aria-label="Close"><img src="image/close-icon.svg" alt="Close" className="dark-theme-icon" onClick={() => setshowfaqPopup(false)} /></button>
                        </div>
                        <div className="accordion popup-scroll" id="accordionExample3">
                          {questions.faq.map((value: any, index: any) => {
                            return (
                              <div className={`accordion-item border-bottom border-0 ${(index === 0 || index === questions.faq.length - 1) ? "rounded=0" : ""}`}>
                                <h2 className="accordion-header my-3" id={`faqpopup-heading${index}`}>
                                  <button className="custom-accordion-btn collapsed w-100 d-flex font-14 grey-v3 text-start align-items-center p-0" type="button" data-bs-toggle="collapse" data-bs-target={`#faqpopup-collapse${index}`} aria-expanded="false" aria-controls={`faqpopup-collapse${index}`}>
                                    {value.questions}
                                  </button>
                                </h2>
                                <div id={`faqpopup-collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`faqpopup-heading${index}`} data-bs-parent="#accordionExample3">
                                  <div className="accordion-body custom-box-shadow custom-left-border mb-3 custom-scroll">
                                    <div className='custom-line'>
                                    <div className="d-flex align-items-center">
                                      <img className="me-2" src="./image/profile-logo-icon.svg" alt="profile-image" />
                                      <span className="font-14 font-semibold">SuperDesk Response</span>
                                    </div>
                                    <p className="mb-0 font-14 color-greyv1 mt-2 custom-lineheight">{value.answers}</p>
                                    <div className="d-md-flex align-items-center mt-3 d-block gap-2">
                                      <div className=" d-flex align-items-center time-with font-12 mb-3 mb-md-0">
                                        <img className="m-1" src="./image/purple-clock.svg" alt="clock-icon" />
                                        <span>Time to Resolve without SuperDesk:<span className="ms-1 font-bold purplev1">{value.timeWithoutSuperDesk}</span></span>
                                      </div>
                                      <div className="d-flex align-items-center time-without font-12">
                                        <img className="m-1" src="./image/green-clock.svg" alt="clock-icon" />
                                        <span>Time to Resolve with SuperDesk:<span className="ms-1 font-bold greenv1">{value.timeWithSuperDesk}</span></span>
                                      </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-backdrop fade show"></div>
            </div>}
          {showissuesPopup &&
            <div className="body-bg model open">
              <div className="modal fade show d-block" id="exampleModalissues" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="false" >
                <div className="modal-dialog modal-dialog-centered service-container">
                  <div className="modal-content custom-border p-3 border-0">
                    <div className="sd-card h-100">
                      {/* Widget Title */}
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <div className="d-flex align-items-center gap-4">
                          <h3 className="font-16 font-medium color-black mb-0">Issues SuperDesk Solved</h3>
                          {/* Tab Button Structure here */}
                          <div className="text-start">
                            <div className="dropdown me-2 d-block d-md-none">
                              <button className="btn btn-secondary dropdown-toggle sd-select" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                Solved
                              </button>
                              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li className="dropdown-item" onClick={() => { setQuestionsReqObj({ ...questionsReqObj, issueType: "Solved" }) }}>Solved</li>
                                <li className="dropdown-item" onClick={() => { setQuestionsReqObj({ ...questionsReqObj, issueType: "Assited" }) }}>Assist On</li>
                              </ul>
                            </div>
                            <ul className="nav nav-pills tab-pill d-md-flex d-none" id="pills-tab" role="tablist">
                              <li className="nav-item" role="presentation">
                                <button className="nav-link tab-title active font-14" data-bs-toggle="pill" data-bs-target="#issue_solved" type="button" role="tab" aria-selected="true" onClick={() => { setQuestionsReqObj({ ...questionsReqObj, issueType: "Solved" }) }}>Solved</button>
                              </li>
                              <li className="nav-item" role="presentation">
                                <button className="nav-link tab-title font-14" data-bs-toggle="pill" data-bs-target="#issue_assist" type="button" role="tab" aria-selected="false" onClick={() => { setQuestionsReqObj({ ...questionsReqObj, issueType: "Assited" }) }}>Assist On</button>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <button type="button" className="btn d-flex align-items-center custom-close p-2 me-2" data-bs-dismiss="modal" aria-label="Close"><img src="image/close-icon.svg" alt="Close" className="dark-theme-icon" onClick={() => setshowissuesPopup(false)} /></button>
                      </div>
                      {/* Tab Structure here */}
                      <div className="tab-content" id="pills-tabContent">
                        {/* Solved here */}
                        <div className="tab-pane fade show active" id="issue_solved" role="tabpanel">
                          <p>Questions</p>
                          <div className="accordion popup-scroll" id="accordionExample-issuespopup">
                            {questions.issues.map((value: any, index: any) => {
                              return (
                                <div className={`accordion-item border-bottom border-0 ${(index === 0 || index === questions.issues.length - 1) ? "rounded=0" : ""}`}>
                                  <h2 className="accordion-header my-3" id={`issuesSolvedpopup-heading${index}`}>
                                    <button className="custom-accordion-btn collapsed w-100 d-flex font-14 grey-v3 text-start align-items-center p-0" type="button" data-bs-toggle="collapse" data-bs-target={`#issuessolvedpopup-collapse${index}`} aria-expanded="false" aria-controls={`issuessolvedpopup-collapse${index}`}>
                                      {value.questions}
                                    </button>
                                  </h2>
                                  <div id={`issuessolvedpopup-collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`issuesSolvedpopup-heading${index}`} data-bs-parent="#accordionExample-issuespopup">
                                    <div className="accordion-body custom-box-shadow custom-left-border mb-3 custom-scroll">
                                      <div className='custom-line'>
                                      <div className="d-flex align-items-center">
                                        <img className="me-2" src="./image/profile-logo-icon.svg" alt="profile-image" />
                                        <span className="font-14 font-semibold">SuperDesk Response</span>
                                      </div>
                                      <p className="mb-0 font-14 color-greyv1 mt-2 custom-lineheight">{value.answers}</p>
                                      <div className="d-md-flex align-items-center mt-3 d-block gap-2">
                                        <div className=" d-flex align-items-center time-with font-12 mb-3 mb-md-0">
                                          <img className="m-1" src="./image/purple-clock.svg" alt="clock-icon" />
                                          <span>Time to Resolve without SuperDesk:<span className="ms-1 font-bold purplev1">{value.timeWithoutSuperDesk}</span></span>
                                        </div>
                                        <div className="d-flex align-items-center time-without font-12">
                                          <img className="m-1" src="./image/green-clock.svg" alt="clock-icon" />
                                          <span>Time to Resolve with SuperDesk:<span className="ms-1 font-bold greenv1">{value.timeWithSuperDesk}</span></span>
                                        </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                        {/* Assist On here */}
                        <div className="tab-pane fade" id="issue_assist" role="tabpanel">
                          <p>Questions</p>
                          <div className="accordion popup-scroll" id="accordionExample-issuesassistedpopup">
                            {questions.issues.map((value: any, index: any) => {
                              return (
                                <div className={`accordion-item border-bottom border-0 ${(index === 0 || index === questions.issues.length - 1) ? "rounded=0" : ""}`}>
                                  <h2 className="accordion-header my-3" id={`issuesassisted-heading${index}`}>
                                    <button className="custom-accordion-btn collapsed w-100 d-flex font-14 grey-v3 text-start align-items-center p-0" type="button" data-bs-toggle="collapse" data-bs-target={`#issuesassistedpopup-collapse${index}`} aria-expanded="false" aria-controls={`issuesassistedpopup-collapse${index}`}>
                                      {value.questions}
                                    </button>
                                  </h2>
                                  <div id={`issuesassistedpopup-collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`issuesassistedpopup-heading${index}`} data-bs-parent="#accordionExample-issuesassistedpopup">
                                    <div className="accordion-body custom-box-shadow custom-left-border mb-3 custom-scroll">
                                      <div className='custom-line'>
                                      <div className="d-flex align-items-center">
                                        <img className="me-2" src="./image/profile-logo-icon.svg" alt="profile-image" />
                                        <span className="font-14 font-semibold">SuperDesk Response</span>
                                      </div>
                                      <p className="mb-0 font-14 color-greyv1 mt-2 custom-lineheight">{value.answers}</p>
                                      <div className="d-md-flex align-items-center mt-3 d-block gap-2">
                                        <div className=" d-flex align-items-center time-with font-12 mb-3 mb-md-0">
                                          <img className="m-1" src="./image/purple-clock.svg" alt="clock-icon" />
                                          <span>Time to Resolve without SuperDesk:<span className="ms-1 font-bold purplev1">{value.timeWithoutSuperDesk}</span></span>
                                        </div>
                                        <div className="d-flex align-items-center time-without font-12">
                                          <img className="m-1" src="./image/green-clock.svg" alt="clock-icon" />
                                          <span>Time to Resolve with SuperDesk:<span className="ms-1 font-bold greenv1">{value.timeWithSuperDesk}</span></span>
                                        </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-backdrop fade show"></div>
            </div>}
          {showrequestsPopup &&
            <div className="body-bg model open">
              <div className="modal fade show d-block" id="exampleModalrequest" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="false" >
                <div className="modal-dialog modal-dialog-centered service-container">
                  <div className="modal-content custom-border p-3 border-0">
                  <div className="sd-card h-100">
                    {/* Widget Title */}
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <div className="d-flex align-items-center gap-4">
                        <h3 className="font-16 font-medium color-black mb-0">Requests SuperDesk Solved</h3>
                        {/* Tab Button Structure here */}
                        <div className="text-start">
                          <div className="dropdown me-2 d-block d-md-none">
                            <button className="btn btn-secondary dropdown-toggle sd-select" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                              Solved
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                              <li className="dropdown-item" onClick={() => { setQuestionsReqObj({ ...questionsReqObj, requestsType: "Solved" }) }}>Solved</li>
                              <li className="dropdown-item" onClick={() => { setQuestionsReqObj({ ...questionsReqObj, requestsType: "Assited" }) }}>Assist On</li>
                            </ul>
                          </div>
                          <ul className="nav nav-pills tab-pill d-md-flex d-none" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                              <button className="nav-link tab-title active font-14" data-bs-toggle="pill" data-bs-target="#request_solvedpopup" type="button" role="tab" aria-selected="true" onClick={() => { setQuestionsReqObj({ ...questionsReqObj, requestsType: "Solved" }) }}>Solved</button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button className="nav-link tab-title font-14" data-bs-toggle="pill" data-bs-target="#request_assistpopup" type="button" role="tab" aria-selected="false" onClick={() => { setQuestionsReqObj({ ...questionsReqObj, requestsType: "Assited" }) }}>Assist On</button>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <button type="button" className="btn d-flex align-items-center custom-close p-2 me-2" data-bs-dismiss="modal" aria-label="Close"><img src="image/close-icon.svg" alt="Close" className="dark-theme-icon" onClick={() => setshowrequestsPopup(false)} /></button>
                    </div>
                    {/* Tab Structure here */}
                    <div className="tab-content" id="pills-tabContent">
                      {/* Solved here */}
                      <div className="tab-pane fade show active" id="request_solvedpopup" role="tabpanel">
                        <p>Questions</p>
                        <div className="accordion popup-scroll" id="accordionExample-requestsolvedpopup">
                          {questions.requests.map((value: any, index: any) => {
                            return (
                              <div className={`accordion-item border-bottom border-0 ${(index === 0 || index === questions.requests.length - 1) ? "rounded=0" : ""}`}>
                                <h2 className="accordion-header my-3" id={`requestsolved-headingpopup${index}`}>
                                  <button className="custom-accordion-btn collapsed w-100 d-flex font-14 grey-v3 text-start align-items-center p-0" type="button" data-bs-toggle="collapse" data-bs-target={`#requestsolved-collapsepopup${index}`} aria-expanded="false" aria-controls={`requestsolved-collapsepopup${index}`}>
                                    {value.questions}
                                  </button>
                                </h2>
                                <div id={`requestsolved-collapsepopup${index}`} className="accordion-collapse collapse" aria-labelledby={`requestsolved-headingpopup${index}`} data-bs-parent="#accordionExample-requestsolvedpopup">
                                  <div className="accordion-body custom-box-shadow custom-left-border mb-3 custom-scroll">
                                    <div className='custom-line'>
                                    <div className="d-flex align-items-center">
                                      <img className="me-2" src="./image/profile-logo-icon.svg" alt="profile-image" />
                                      <span className="font-14 font-semibold">SuperDesk Response</span>
                                    </div>
                                    <p className="mb-0 font-14 color-greyv1 mt-2 custom-lineheight">{value.answers}</p>
                                    <div className="d-md-flex align-items-center mt-3 d-block gap-2">
                                      <div className=" d-flex align-items-center time-with font-12 mb-3 mb-md-0">
                                        <img className="m-1" src="./image/purple-clock.svg" alt="clock-icon" />
                                        <span>Time to Resolve without SuperDesk:<span className="ms-1 font-bold purplev1">{value.timeWithoutSuperDesk}</span></span>
                                      </div>
                                      <div className="d-flex align-items-center time-without font-12">
                                        <img className="m-1" src="./image/green-clock.svg" alt="clock-icon" />
                                        <span>Time to Resolve with SuperDesk:<span className="ms-1 font-bold greenv1">{value.timeWithSuperDesk}</span></span>
                                      </div>
                                    </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      {/* Assist On here */}
                      <div className="tab-pane fade" id="request_assistpopup" role="tabpanel">
                        <p>Questions</p>
                        <div className="accordion popup-scroll" id="accordionExample-requestassistedpopup">
                          {questions.requests.map((value: any, index: any) => {
                            return (
                              <div className={`accordion-item border-bottom border-0 ${(index === 0 || index === questions.requests.length - 1) ? "rounded=0" : ""}`}>
                                <h2 className="accordion-header my-3" id={`requestassisted-headingpopup${index}`}>
                                  <button className="custom-accordion-btn collapsed w-100 d-flex font-14 grey-v3 text-start align-items-center p-0" type="button" data-bs-toggle="collapse" data-bs-target={`#requestassisted-collapsepopup${index}`} aria-expanded="false" aria-controls={`requestassisted-collapsepopup${index}`}>
                                    {value.questions}
                                  </button>
                                </h2>
                                <div id={`requestassisted-collapsepopup${index}`} className="accordion-collapse collapse" aria-labelledby={`requestassisted-headingpopup${index}`} data-bs-parent="#accordionExample-requestassistedpopup">
                                  <div className="accordion-body custom-box-shadow custom-left-border mb-3">
                                  <div className='custom-line'>
                                    <div className="d-flex align-items-center">
                                      <img className="me-2" src="./image/profile-logo-icon.svg" alt="profile-image" />
                                      <span className="font-14 font-semibold">SuperDesk Response</span>
                                    </div>
                                    <p className="mb-0 font-14 color-greyv1 mt-2 custom-lineheight">{value.answers}</p>
                                    <div className="d-md-flex align-items-center mt-3 d-block gap-2">
                                      <div className=" d-flex align-items-center time-with font-12 mb-3 mb-md-0">
                                        <img className="m-1" src="./image/purple-clock.svg" alt="clock-icon" />
                                        <span>Time to Resolve without SuperDesk:<span className="ms-1 font-bold purplev1">{value.timeWithoutSuperDesk}</span></span>
                                      </div>
                                      <div className="d-flex align-items-center time-without font-12">
                                        <img className="m-1" src="./image/green-clock.svg" alt="clock-icon" />
                                        <span>Time to Resolve with SuperDesk:<span className="ms-1 font-bold greenv1">{value.timeWithSuperDesk}</span></span>
                                      </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
              <div className="modal-backdrop fade show"></div>
            </div>}
          {showViewPopup &&
            <div className="body-bg model open">
              <div className="modal fade show d-block" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content custom-border p-3 py-4 border-0">
                    <div className="d-flex justify-content-between align-items-center mb-3 mt-2">
                      <h1 className="font-20 shadow-black font-semibold ms-3">{viewTitle}</h1>
                      <div className="d-flex justify-content-end">
                        <button type="button" className="btn d-flex align-items-center custom-close p-2 me-2" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setshowViewPopup(false); setviewCategory(""); setviewJustification(""); setviewTitle(""); setviewQuestion("") }}><img src="image/close-icon.svg" alt="Close" className="dark-theme-icon" /></button>
                      </div>
                    </div>
                    <div className="modal-body p-0 px-3">
                      <h4 className="font-14 font-semibold">Question</h4>
                      <a href="#" className="font-14 font-regular color-anchor-link">{viewQuestion}</a>
                      <h4 className="font-14 font-semibold mt-4">Justification</h4>
                      <p className="font-14 font-regular color-grey-v3 mb-4">{viewJustification}</p>
                      <h4 className="font-14 font-semibold mb-2 pb-1">Category</h4>
                      <p className="mt-2"><span className={`custom-btn-v${viewCategory==="Complex Scenario"?"1":viewCategory==="Vague Questions"?"2":viewCategory==="HR Policy Interpretations"?"3":viewCategory==="Advanced Technical"?"4":"5"} text-nowrap text-center px-3 py-1 font-semibold font-13`}>{viewCategory}</span></p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-backdrop fade show"></div>
            </div>}
            {sessionExpired &&<SessionExpiredPopup/>}
        </div>
      </div>
    </div>
    // <div>
    // <button onClick={handleChangePassword}>Change Password</button>
    // <button onClick={handleLogout}>Logout</button>
    // </div>

  );
};

export default HRSDDashboard;