import { useNavigate } from "react-router-dom";
import { authToken, getReportData, mailId, userName } from "../constant";
import { useEffect, useState } from "react";
import jwt from "jsonwebtoken";
import { logout } from "../apiservices/signIn/api";
const LeftsidePanel = () => {
  const navigate = useNavigate();
  const userName = sessionStorage.getItem("user") || "";
  const role = sessionStorage.getItem("role") || "";
  const mailId = sessionStorage.getItem("useremail") || "";
  const [token, setToken] = useState(authToken);
  const logoutSession = async () => {
    try {
      const logoutUser = await logout();
      if (logoutUser.status !== 200) console.log("API Failed");
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div className="side-nav-container">
      {/* Side Nav - Starts here */}
      <div
        className="d-flex flex-column flex-shrink-0 p-3 theme-black vh-100 display-mobtab-none position-relative"
        id="navWid"
        style={{ width: "280px" }}
      >
        <span
          className="text-center my-4"
          id="content-logo-collapse"
          style={{ height: "33px" }}
        >
          <a href="#" className=" d-inline-block ">
            <img src="image/super-desk-logo-full.svg" alt="superdesk-logo" />
          </a>
        </span>
        <ul className="nav nav-pills flex-column mb-auto flex-nowrap nav-overflow mt-4">
          {(role === "ITOPS" || role === "Admin") && (
            <li
              className={`side-nav-item d-flex align-items-center mb-3 font-14 font-medium gap-3 ${
                window.location.pathname.toLowerCase() === "/itops"
                  ? "active"
                  : ""
              }`}
              onClick={() => navigate("/itops")}
            >
              <img src="image/it-ops-icon.svg" alt="hub-icon" />{" "}
              <span className="navName d-inline-block">IT Ops</span>
            </li>
          )}
          {(role === "Customer Service" || role === "Admin") && (
            <li
              className={`side-nav-item d-flex align-items-center mb-3 font-14 font-medium gap-3 ${
                window.location.pathname.toLowerCase() === "/customerservice"
                  ? "active"
                  : ""
              }`}
              onClick={() => navigate("/customerService")}
            >
              <img src="image/customer-service-icon.svg" alt="cs-icon" />{" "}
              <span className="navName d-inline-block">Customer Service</span>
            </li>
          )}
          {(role === "HRSD" || role === "Admin") && (
            <li
              className={`side-nav-item d-flex align-items-center mb-3 font-14 font-medium gap-3 ${
                window.location.pathname.toLowerCase() === "/hrsd"
                  ? "active"
                  : ""
              }`}
              onClick={() => navigate("/hrsd")}
            >
              <img src="image/hrsd-icon.svg" alt="hrsd-icon" />{" "}
              <span className="navName d-inline-block">HRSD</span>
            </li>
          )}
          {(role === "Cloud" || role === "Admin") && (
            <li
              className={`side-nav-item d-flex align-items-center mb-3 font-14 font-medium gap-3 ${
                window.location.pathname.toLowerCase() === "/cloudcost"
                  ? "active"
                  : ""
              }`}
              onClick={() => navigate("/cloudCost")}
            >
              <img src="image/cloud-cost-icon.svg" alt="cc-icon" />{" "}
              <span className="navName d-inline-block">Cloud Cost</span>
            </li>
          )}
        </ul>
        <div
          className="conten-between position-absolute end-0 start-0 bottom-0  px-3 py-2 mx-3 mb-2 border-radius-12"
          id="content-collapse"
        >
          <div className="d-flex gap-2 align-items-center">
            <span className="block">
              <span className="cust-notify-userprof d-flex align-items-center justify-content-center green-notify-userprof font-medium font-16">
                {userName
                  ?.split(" ")
                  .map((name: any) => name.charAt(0))
                  .join("")}
              </span>
            </span>
            <div className="navName d-inline-block">
              <span className="d-block font-14 font-regular text-white">
                {userName}
              </span>
            </div>
          </div>
          <div className="btn-group dropend">
            <button
              type="button"
              className="btn dropdown-toggle border-none px-0 shadow-none"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="image/logout-arrow.svg"
                alt="logout-arrow"
                className="mb-1 action-icon-style"
              />
            </button>
            <ul className="dropdown-menu profile-menu">
              <li className="d-flex gap-2 align-items-center border-bottom">
                <span className="block">
                  <span className="cust-notify-userprof d-flex align-items-center justify-content-center green-notify-userprof font-medium font-16">
                    {userName
                      ?.split(" ")
                      .map((name: any) => name.charAt(0))
                      .join("")}
                  </span>
                </span>
                <div className="navName d-inline-block ms-1">
                  <span className="d-block font-16 font-medium">
                    {userName}
                  </span>
                  <span className="d-block font-14 color-greyv1 font-regular">
                    {mailId}
                  </span>
                </div>
              </li>
              <li className="font-14 font-regular color-black border-bottom">
                <a className="dropdown-item p-0" href="/resetPasswordHub">
                  <img
                    className="pe-2 mb-1"
                    src="image/change-password-icon.svg"
                  />
                  Change Password
                </a>
              </li>
              <li className="font-14 font-regular">
                <a
                  className="dropdown-item p-0 color-red"
                  href="/"
                  onClick={async () => {
                    logoutSession();
                    sessionStorage.clear();
                  }}
                >
                  <img className="pe-2 mb-1" src="image/log-out-icon.svg" />
                  Log Out
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <nav className="navbar navbar-expand-md navbar-light theme-black py-3 display-mobtab-block w-100">
        <div className="container-fluid">
          <a className="navbar-brand ms-2" href="#">
            <img
              src="image/SuperDesk-logo.svg"
              alt="brand-logo"
              width="140px"
            />
          </a>
          <button
            className="navbar-toggler custom-toggler collapsed"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#sideNavOffCanvas"
            aria-expanded="false"
          >
            <span className="navbar-toggler-icon" />
          </button>
        </div>
      </nav>
      <div
        className="offcanvas offcanvas-start custom-width theme-black"
        tabIndex={-1}
        id="sideNavOffCanvas"
        style={{ zIndex: 9999 }}
      >
        <div className="offcanvas-header">
          <span
            className="text-center my-4"
            id="content-logo-collapse"
            style={{ height: "33px" }}
          >
            <a href="#" className=" d-inline-block ">
              <img src="image/super-desk-logo-full.svg" alt="superdesk-logo" />
            </a>
          </span>
        </div>
        <div className="offcanvas-body">
          <ul className="nav nav-pills flex-column mb-auto flex-nowrap nav-overflow mt-4">
            {(role === "ITOPS" || role === "Admin") && (
              <li
                className={`side-nav-item d-flex align-items-center mb-3 font-14 font-medium gap-3 ${
                  window.location.pathname.toLowerCase() === "/itops"
                    ? "active"
                    : ""
                }`}
                onClick={() => navigate("/itops")}
              >
                <img src="image/it-ops-icon.svg" alt="hub-icon" />{" "}
                <span className="navName d-inline-block">IT Ops</span>
              </li>
            )}
            {(role === "Customer Service" || role === "Admin") && (
              <li
                className={`side-nav-item d-flex align-items-center mb-3 font-14 font-medium gap-3  ${
                  window.location.pathname.toLowerCase() === "/customerservice"
                    ? "active"
                    : ""
                }`}
                onClick={() => navigate("/customerService")}
              >
                <img src="image/customer-service-icon.svg" alt="cs-icon" />{" "}
                <span className="navName d-inline-block">Customer Service</span>
              </li>
            )}
            {(role === "HRSD" || role === "Admin") && (
              <li
                className={`side-nav-item d-flex align-items-center mb-3 font-14 font-medium gap-3  ${
                  window.location.pathname.toLowerCase() === "/hrsd"
                    ? "active"
                    : ""
                }`}
                onClick={() => navigate("/hrsd")}
              >
                <img src="image/hrsd-icon.svg" alt="hrsd-icon" />{" "}
                <span className="navName d-inline-block">HRSD</span>
              </li>
            )}
            {(role === "Cloud" || role === "Admin") && (
              <li
                className={`side-nav-item d-flex align-items-center mb-3 font-14 font-medium gap-3  ${
                  window.location.pathname.toLowerCase() === "/cloudcost"
                    ? "active"
                    : ""
                }`}
                onClick={() => navigate("/cloudCost")}
              >
                <img src="image/cloud-cost-icon.svg" alt="cc-icon" />{" "}
                <span className="navName d-inline-block">cloudCost</span>
              </li>
            )}
          </ul>
          <div
            className="conten-between position-absolute end-0 start-0 bottom-0  px-3 py-2 mx-3 mb-2 border-radius-12"
            id="content-collapse"
          >
            <div className="d-flex gap-2 align-items-center">
              <span className="block">
                <span className="cust-notify-userprof d-flex align-items-center justify-content-center green-notify-userprof font-medium font-16">
                  {userName
                    ?.split(" ")
                    .map((name: any) => name.charAt(0))
                    .join("")}
                </span>
              </span>
              <div className="navName d-inline-block">
                <span className="d-block font-14 font-regular text-white">
                  {userName}
                </span>
              </div>
            </div>
            <div className="btn-group dropend">
              <button
                type="button"
                className="btn dropdown-toggle border-none px-0 shadow-none"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src="image/logout-arrow.svg" alt="logout-arrow" />
              </button>
              <ul className="dropdown-menu profile-menu">
                <li className="d-flex gap-2 align-items-center border-bottom">
                  <span className="block">
                    <span className="cust-notify-userprof d-flex align-items-center justify-content-center green-notify-userprof font-medium font-16">
                      {userName
                        ?.split(" ")
                        .map((name: any) => name.charAt(0))
                        .join("")}
                    </span>
                  </span>
                  <div className="navName d-inline-block ms-1">
                    <span className="d-block font-16 font-medium">
                      {userName}
                    </span>
                    <span className="d-block font-14 color-greyv1 font-regular">
                      {mailId}
                    </span>
                  </div>
                </li>
                <li className="font-14 font-regular color-black border-bottom ">
                  <a className="dropdown-item p-0" href="/resetPasswordHub">
                    <img
                      className="pe-2 mb-1"
                      src="image/change-password-icon.svg"
                    />
                    Change Password
                  </a>
                </li>
                <li className="font-14 font-regular ">
                  <a
                    className="dropdown-item p-0 color-red"
                    href="/"
                    onClick={async () => {
                      logoutSession();
                      sessionStorage.clear();
                    }}
                  >
                    <img className="pe-2 mb-1" src="image/log-out-icon.svg" />
                    Log Out
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftsidePanel;
